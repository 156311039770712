/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";

import foto_ei from "assets/images/Zelf/Zelf-41.jpg"
import foto_kiem from "assets/images/Zelf/Zelf-45.jpg"

function Recepten() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
              Lekkere recepten
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={0.75}></Grid>
          <Grid item xs={12} lg={5} mt={"2em"}>
            <RotatingCard>
              <RotatingCardFront
                image={foto_kiem}
                icon="touch_app"
                title="Kiemzaad 1"
                description="Neem zoveel zaad als je nodig hebt voor je parkieten. Strooi het zaad in een schaal en voeg het water toe totdat het zaad helemaal bedekt is. Laat het geheel 24 uur staan, na deze tijd moet je het zaad in een zeef heel goed omspoelen. Hierna doe je het zaad goed verspreid terug in de schaal en leg er een natte doek (bv theedoek)over heen, op deze manier hebben ze genoeg vocht. Na een poos zie je dat het zaad gaat kiemen. Spoel het zaad nogmaals goed uit. Leg alles verspreid op een stuk keukenrol, je kan eventueel de bovenkant voorzichtig droogdeppen. Als het stuk keukenrol te nat is geworden kan je beter een nieuw stuk pakken anders droogt het zaad niet goed. Wacht niet te lang met geven van het kiemzaad want anders verdwijnen alle voedingstoffen die er in zitten"
              />
              <RotatingCardBack
                image={foto_kiem}
                title="Benodigdheden"
                description={
                  <ul>
                    <li>Zaad</li>
                    <li>Een schaal</li>
                    <li>Zeef</li>
                    <li>Keukenpapier</li>
                    <li>Water</li>
                  </ul>
                }
                
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={0.5}></Grid>
          <Grid item xs={12} lg={5} mt={"2em"}>
            <RotatingCard>
              <RotatingCardFront
                image={foto_kiem}
                icon="touch_app"
                title="Kiemzaad 2"
                description={
                  <>
                    <p>
                      Gebruik een bakje zo groot al je nodig denk te hebben (ligt aan hoeveel parkieten je kiemzaad geeft). Doe de potgrond in het bakje en strooi het zaad er over heen. Maak het geheel af met een dun laagje potgrond, zodat het zaad beschermd is. Geef een klein beetje water en na een dag of wat zie je de kiemplantjes verschijnen.
                    </p>
                    <p>
                      Het gekiemde zaad moet ongeveer een uitloop hebben van 2mm dan zitten de meeste vitamine er nog in. De foto hierboven is gemaakt om te laten zien hoe het kiemvoer er uit ziet als je het lang laat staan, het is dan echt een plantje. In werkelijkheid zit er aan het zaad na het kiemen een klein wit worteltje, foto volgt nog!
                    </p>
                    <p>
                      Het bereiden van kiemvoer wordt ook als versheidsproef gebruikt, wanneer 50% of meer van de zaden uitkomen, is het zaad vers. En zo niet is het voer oud en niet goed om te geven.
                    </p>
                  </>
                }
              />
              <RotatingCardBack
                image={foto_kiem}
                title="Benodigdheden"
                description={
                  <ul>
                    <li>Zaad</li>
                    <li>Bakje</li>
                    <li>Aarde/Potgrond</li>
                  </ul>
                }
                
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={0.75}></Grid>


          <Grid item xs={12} lg={0.75}></Grid>
          <Grid item xs={12} lg={5} mt={"2em"}>
            <RotatingCard>
              <RotatingCardFront
                image="https://bit.ly/3G5JXJZ"
                icon="touch_app"
                title="Vitaminebom"
                description="Roer de brinta op met een beetje water met daarin roosvicee. Voeg het parkietenzaad toe en roer het geheel goed door. Er ontstaat een rullige massa."
              />
              <RotatingCardBack
                image="https://bit.ly/32ZoTGx"
                title="Benodigdheden"
                description={
                  <ul>
                    <li>1 deel brinta</li>
                    <li>2 delen parkietenzaad</li>
                    <li>water met roosvicee</li>
                  </ul>
                }
                
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={0.5}></Grid>
          <Grid item xs={12} lg={5} mt={"2em"}>
            <RotatingCard>
              <RotatingCardFront
                image="https://bit.ly/3G5JXJZ"
                icon="touch_app"
                title="Parkietencocktail"
                description="Vul een drinkbakje met water. Voeg hier een scheutje roosvicee aan toe. Roer het geheel door elkaar zodat het goed gemengt is."
              />
              <RotatingCardBack
                image="https://bit.ly/32ZoTGx"
                title="Benodigdheden"
                description={
                  <ul>
                    <li>Water</li>
                    <li>roosvicee</li>
                  </ul>
                }
                
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={0.75}></Grid>


          <Grid item xs={12} lg={0.75}></Grid>
          <Grid item xs={12} lg={5} mt={"2em"}>
            <RotatingCard>
              <RotatingCardFront
                image={foto_ei}
                icon="touch_app"
                title="Eivoer"
                description={
                  <>
                    <p>
                    Kook een ei, doe het eigeel in een bakje en prak het fijn. Kruimel een stuk beschuit en doe dit bij het eigeel, Voeg een paar druppels magere kwark toe, en meng het geheel tot een lekkere massa.
                    </p>
                    <p>
                    Het kan ook zonder kwark net wat je wil en natuurlijk kan je ook nog fruit zoals bijvoorbeeld stukjes appel toevoegen Je moet zelf een beetje kijken wat je parkiet het lekkerste vind!
                    </p>
                  </>
                }
              />
              <RotatingCardBack
                image={foto_ei}
                title="Benodigdheden"
                description={
                  <ul>
                    <li>ei</li>
                    <li>magere kwark</li>
                    <li>beschuit</li>
                  </ul>
                }
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={0.5}></Grid>
          <Grid item xs={12} lg={5}></Grid>
          <Grid item xs={12} lg={0.75}></Grid>

        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Recepten;
