/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Foto from "assets/images/Zelf/Zelf-74.jpg"

function Vitaminen() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
              Vitaminen
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Vitaminen
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Als wij mensen te weinig vitamine binnen krijgen worden we op den duur ziek, je hebt weinig weerstand en wordt bijvoorbeeld snel verkouden.
            Voor parkieten zijn vitamine net zo belangrijk als voor ons. Parkieten die niet genoeg aanvullende voeding krijgen en alleen op zaad leven worden ziek en overlijden tenslotte als gevolg aan een zwaar vitaminegebrek. Ook al zijn parkieten vooral zaadeters het is toch van levensbelang dat ze gevarieerd eten en zo voldoende vitamine binnenkrijgen.
            Eigenlijk is het helemaal niet moeilijk om een parkiet gevarieerd te laten eten. Het is wel heel belangrijk om een parkiet al van jongs af aan zoveel mogelijk te laten proeven van het voedsel dat gezond voor hem/haar is. Als ze wat ouder worden is het soms moeilijker om ze te laten wennen aan iets wat ze nog nooit gekregen/gegeten hebben.
            Buiten het normale zaad is het regelmatig aanbieden van allerlei soorten groenten en fruit erg belangrijk. Over het algemeen kan je een parkiet alle soorten groenten geven behalve avocado want deze is zeer giftig voor de parkiet. Denk bijvoorbeeld aan sperzieboontjes, spinazie, andijvie, witlof, sla, broccoli, wortel, stukje komkommer etc. Fruit is ook een goede vitamine bron, denk dan bijvoorbeeld aan peer, appel, druif, banaan, aardbei, rozenbotel etc. Groenvoer is ook uitstekend voor de gezondheid van de parkiet, oa weegbree, vogelmuur, peterselie en gekiemd zaad, behoren tot de mogelijkheden.
            </p>
            <p style={{marginBottom:"1em"}}>
            Spoel wel alles goed af onder water en geef niets waaraan je twijfelt. Geef niet teveel in één keer, geef van sla een klein blaadje want teveel kan lijden tot diarree, Geef ook andijvie en spinazie in kleine hoeveelheden want in deze groenten zit veel ijzer.
            </p>
          </Grid>
          
          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Vitamine A
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Het meest voorkomend is een gebrek aan vitamine A Als een parkiet niet genoeg vitamine A binnen krijgt is de kans op infectie groter, vooral de ogen, snavel en de luchtwegen zijn dan extra vatbaar voor infectie. Bij een gebrek aan vitamine A zie je oa dat de ogen tranen en dat er problemen zijn met de ademhaling. Tevens geeft een gebrek aan deze vitamine problemen bij de vruchtbaarheid en de veren van de parkiet. Vitamine A zit in dierlijke producten, je kunt bijvoorbeeld af en toe een heel klein stukje kaas of gekookt vlees geven en natuurlijk is eivoer ook belangrijk. In Kiemzaad en peterselie zit ook veel vitamine A Voldoende groenvoer is zoals eerder aangegeven hier ook van belang In groenvoer zit namelijk Pro-vitamine A, hieruit kan de parkiet zelf vitamine A aanmaken.
            </p>
          </Grid>
          
          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Vitamine B
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Vitamine B is vooral belangrijk voor het behoud van een goede spijsvertering, de groei van de veren, de spieren en het zenuwstelsel. Een tekort aan deze vitamine kan oa ruwe pootjes, ontstoken ogen, en vergroeiingen aan de tenen, veroorzaken en problemen geven aan het spijsverteringskanaal. Een tekort kan zelfs ook tot verlammingsverschijnselen en krampverschijnselen lijden. Vitamine B zit vooral in groenvoer, granen, dierlijke producten, etc.
            </p>
          </Grid>
          
          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Vitamine C
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Vitamine C kan door de parkiet zelf worden aangemaakt, het geven van goede voeding is daarbij erg belangrijk. Een goede bron van vitamine C zit in het volgende. Meng 1 eetlepel parkietenzaad met 1 eetlepel eivoer, 1 eetlepel brinta en een paar druppels water met roosvicee er in. Eventueel kan je er ook nog wat geraspte appel en/of wortel bij doen. Tevens zit er ook veel vitamine C in Vogelmuur.
            </p>
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Vitamine D
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Vitamine D is belangrijk voor de opname van fosfor en kalk in het bloed van de parkiet, Fosfor en kalk zijn zeer belangrijk voor de opbouw van het skelet bij een jonge parkiet. Een te kort aan vitamine D kan oa een snavelafwijking veroorzaken en problemen geven aan de nagels zoals verkeerde groei van de nagels. Tevens ontstaan er problemen met de groei van de botten en kunnen er vergroeiing aan de tenen ontstaan. Het geven van Grit, sepiaschelp, kalkblok, mineralenblok is hierbij belangrijk.
            </p>
            <p style={{marginBottom:"1em"}}>
            Vitamine D wordt ook door de parkiet opgenomen uit de zon en daglicht. Het is daarom goed om de parkiet van de zon te laten genieten. Let er uiteraard wel op dat de parkiet het niet te warm krijgt en zet hem niet in de volle zon op het warmste tijdstip van de dag. Leg op de helft van de kooi een doek zodat de parkiet de schaduw op kan zoeken als hij daar behoefte aan heeft.
            </p>
          </Grid>
          
          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Vitamine E
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Vitamine E is belangrijk voor de groei, stofwisseling/spijsvertering en de voortplanting van de parkiet. Een te kort aan deze vitamine kan oa evenwichtsstoornissen veroorzaken. Gevarieerde voeding is belangrijk.
            </p>
            <p style={{marginBottom:"1em"}}>
            Ook een teveel aan vitamine kan tot problemen lijden. Probeer de balans een beetje te vinden. Wannneer je de parkiet voorziet van een afwisselend en gevarieerd menu met de juiste producten, blijft je parkiet gezond en heb je veel minder kans op ziekte/infectie. Een parkiet zal niet gelijk alle groenten/fruit lusten, blijf alles aanbieden totdat de parkiet er aan gewend raakt. Het is net als bij de mensen dat niet iedereen alles lust het is gewoon uittesten en proberen wat jou parkiet graag eet.
            </p>
          </Grid>
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Vitaminen;
