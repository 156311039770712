/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import * as React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Foto from "assets/images/Zelf/Zelf-74.jpg"
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function ParkietenNamen() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
              Parkieten namen
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <p style={{marginBottom:"1em"}}>
              Dit is een recente namen lijst voor parkietachtige die door eigenaren geven zijn aan hun parkiet.
              Deze namen lijst is tot stand gekomen d.m.v. polls op hyves en eigen ervaring en de oude lijst van de website.
              De eerste poll staat op de hyves "Een parkiet is lief!" gemaakt door Paulo.
              De tweede poll staat op de hyves "valkparkieten" gemaakt door Ivan Deckers.
              Met dank voor jullie medewerking.
            </p>
            <p style={{marginBottom:"1em"}}>
              De meeste parkieten namen beginnen met de letter
            </p>
          </Grid>
          <Grid item xs={1}><b>Top 3</b></Grid>
          <Grid item xs={1}><b>Letter</b></Grid>
          <Grid item xs={1}><b>Aantal</b></Grid>
          <Grid item xs={9}></Grid>
          <Grid item xs={1}><b>1</b></Grid>
          <Grid item xs={1}>M</Grid>
          <Grid item xs={1}>21</Grid>
          <Grid item xs={9}></Grid>
          <Grid item xs={1}><b>2</b></Grid>
          <Grid item xs={1}>P</Grid>
          <Grid item xs={1}>20</Grid>
          <Grid item xs={9}></Grid>
          <Grid item xs={1}><b>3</b></Grid>
          <Grid item xs={1}>S</Grid>
          <Grid item xs={1}>18</Grid>
          
          <Grid item xs={12} mt="1em">
            De meeste grbruikte parkieten namen zijn
          </Grid>

          <Grid item xs={1}><b>Top 5</b></Grid>
          <Grid item xs={1}><b>Naam</b></Grid>
          <Grid item xs={1}><b>Aantal</b></Grid>
          <Grid item xs={9}></Grid>
          <Grid item xs={1}><b>1</b></Grid>
          <Grid item xs={1}>Tweety</Grid>
          <Grid item xs={1}>8x</Grid>
          <Grid item xs={9}></Grid>
          <Grid item xs={1}><b>2</b></Grid>
          <Grid item xs={1}>Rocky</Grid>
          <Grid item xs={1}>6x</Grid>
          <Grid item xs={9}></Grid>
          <Grid item xs={1}><b>3</b></Grid>
          <Grid item xs={1}>Snowy</Grid>
          <Grid item xs={1}>5x</Grid>
          <Grid item xs={9}></Grid>
          <Grid item xs={1}><b>4</b></Grid>
          <Grid item xs={1}>Bassie</Grid>
          <Grid item xs={1}>4x</Grid>
          <Grid item xs={9}></Grid>
          <Grid item xs={1}><b>5</b></Grid>
          <Grid item xs={1}>Rambo<br />Ricky</Grid>
          <Grid item xs={1}>3x</Grid>


          <Grid item xs={12}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <MKTypography>A</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>A.J.</Grid >
                    <Grid xs={3}>Alejandro</Grid >
                    <Grid xs={3}>Ali</Grid >
                    <Grid xs={3}>Amy</Grid >
                    <Grid xs={3}>Angel</Grid >
                    <Grid xs={3}>Angie</Grid >
                    <Grid xs={3}>Appie</Grid >
                    <Grid xs={3}>Avy</Grid >
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <MKTypography>B</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Baco</Grid>
                    <Grid xs={3}>Banana</Grid>
                    <Grid xs={3}>Bassie</Grid>
                    <Grid xs={3}>Bauthy</Grid>
                    <Grid xs={3}>Belive</Grid>
                    <Grid xs={3}>Benji</Grid>
                    <Grid xs={3}>Bibi</Grid>
                    <Grid xs={3}>Binky</Grid>
                    <Grid xs={3}>Birdy</Grid>
                    <Grid xs={3}>Bo</Grid>
                    <Grid xs={3}>Bokito</Grid>
                    <Grid xs={3}>Bram</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                <MKTypography>C</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Calimero</Grid>
                    <Grid xs={3}>Charlie</Grid>
                    <Grid xs={3}>Chico</Grid>
                    <Grid xs={3}>Chipie</Grid>
                    <Grid xs={3}>Chiquita</Grid>
                    <Grid xs={3}>Chucky</Grid>
                    <Grid xs={3}>City</Grid>
                    <Grid xs={3}>Co/Coco</Grid>
                    <Grid xs={3}>Cody</Grid>
                    <Grid xs={3}>Coeky</Grid>
                    <Grid xs={3}>Cookie</Grid>
                    <Grid xs={3}>Crocky</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
              <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                <MKTypography>D</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Daffy</Grid>
                    <Grid xs={3}>Daisy</Grid>
                    <Grid xs={3}>Deedee</Grid>
                    <Grid xs={3}>Denzel</Grid>
                    <Grid xs={3}>Dexter</Grid>
                    <Grid xs={3}>Diesel</Grid>
                    <Grid xs={3}>Dingo</Grid>
                    <Grid xs={3}>Dipsy</Grid>
                    <Grid xs={3}>Dokie</Grid>
                    <Grid xs={3}>Dora</Grid>
                    <Grid xs={3}>Dory</Grid>
                    <Grid xs={3}>Ducky</Grid>
                    <Grid xs={3}>Dymond</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
              <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                <MKTypography>E</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Eagle</Grid>
                    <Grid xs={3}>Eddo</Grid>
                    <Grid xs={3}>Eddy</Grid>
                    <Grid xs={3}>Elco</Grid>
                    <Grid xs={3}>Emy</Grid>
                    <Grid xs={3}>Essy</Grid>
                    <Grid xs={3}>Evy</Grid>
                    <Grid xs={3}>Exster</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
              <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                <MKTypography>F</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Falco</Grid>
                    <Grid xs={3}>Fladder</Grid>
                    <Grid xs={3}>Flippie</Grid>
                    <Grid xs={3}>Flippo</Grid>
                    <Grid xs={3}>Foofie</Grid>
                    <Grid xs={3}>Frits</Grid>
                    <Grid xs={3}>Frodo</Grid>
                    <Grid xs={3}>Frutsel</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
              <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                <MKTypography>G</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Gabber</Grid>
                    <Grid xs={3}>Gerda</Grid>
                    <Grid xs={3}>Gijs</Grid>
                    <Grid xs={3}>Gizmo</Grid>
                    <Grid xs={3}>Goofy</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
              <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                <MKTypography>H</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Happy</Grid>
                    <Grid xs={3}>Harry</Grid>
                    <Grid xs={3}>Heppie</Grid>
                    <Grid xs={3}>Hoops</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
              <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
                <MKTypography>I</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Iep</Grid>
                    <Grid xs={3}>Igor</Grid>
                    <Grid xs={3}>Invierno</Grid>
                    <Grid xs={3}>Itchy</Grid>
                    <Grid xs={3}>Izzy</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
              <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
                <MKTypography>J</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Jack</Grid>
                    <Grid xs={3}>Jacky</Grid>
                    <Grid xs={3}>Jansen</Grid>
                    <Grid xs={3}>Jelle</Grid>
                    <Grid xs={3}>Jeroentje</Grid>
                    <Grid xs={3}>Jodie</Grid>
                    <Grid xs={3}>Joep</Grid>
                    <Grid xs={3}>Joepie</Grid>
                    <Grid xs={3}>Joey</Grid>
                    <Grid xs={3}>Jojo</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
              <AccordionSummary aria-controls="panel11d-content" id="panel11d-header">
                <MKTypography>K</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Kareltje</Grid>
                    <Grid xs={3}>Kees</Grid>
                    <Grid xs={3}>Kike</Grid>
                    <Grid xs={3}>Kiki</Grid>
                    <Grid xs={3}>Kiko</Grid>
                    <Grid xs={3}>Koos</Grid>
                    <Grid xs={3}>Kokkie</Grid>
                    <Grid xs={3}>Koko</Grid>
                    <Grid xs={3}>Krumpie</Grid>
                    <Grid xs={3}>Kuifje</Grid>
                    <Grid xs={3}>Kuko</Grid>
                    <Grid xs={3}>Kwekkie</Grid>
                    <Grid xs={3}>Kyra</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
              <AccordionSummary aria-controls="panel12d-content" id="panel12d-header">
                <MKTypography>L</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Lennie</Grid>
                    <Grid xs={3}>Lester</Grid>
                    <Grid xs={3}>Lilly</Grid>
                    <Grid xs={3}>Lima</Grid>
                    <Grid xs={3}>Lilo</Grid>
                    <Grid xs={3}>Lizzy</Grid>
                    <Grid xs={3}>Loco</Grid>
                    <Grid xs={3}>Loekie</Grid>
                    <Grid xs={3}>Lorrie</Grid>
                    <Grid xs={3}>Louis</Grid>
                    <Grid xs={3}>Lucky</Grid>
                    <Grid xs={3}>Lucy</Grid>
                    <Grid xs={3}>Luna</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
              <AccordionSummary aria-controls="panel13d-content" id="panel13d-header">
                <MKTypography>M</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Macho</Grid>
                    <Grid xs={3}>Mango</Grid>
                    <Grid xs={3}>Manu</Grid>
                    <Grid xs={3}>Marco</Grid>
                    <Grid xs={3}>Maslow</Grid>
                    <Grid xs={3}>Maxi</Grid>
                    <Grid xs={3}>Messi</Grid>
                    <Grid xs={3}>Mickey</Grid>
                    <Grid xs={3}>Milcoo</Grid>
                    <Grid xs={3}>Mila</Grid>
                    <Grid xs={3}>Milo</Grid>
                    <Grid xs={3}>Misty</Grid>
                    <Grid xs={3}>Moby</Grid>
                    <Grid xs={3}>Moestie</Grid>
                    <Grid xs={3}>Moon</Grid>
                    <Grid xs={3}>Mouse</Grid>
                    <Grid xs={3}>Mulan</Grid>
                    <Grid xs={3}>Murphy</Grid>
                    <Grid xs={3}>Mus</Grid>
                    <Grid xs={3}>Mushu</Grid>
                    <Grid xs={3}>Mythos</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
              <AccordionSummary aria-controls="panel14d-content" id="panel14d-header">
                <MKTypography>N</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Nemo</Grid>
                    <Grid xs={3}>Nickey</Grid>
                    <Grid xs={3}>Nico</Grid>
                    <Grid xs={3}>Nikita</Grid>
                    <Grid xs={3}>Noa</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
              <AccordionSummary aria-controls="panel15d-content" id="panel15d-header">
                <MKTypography>O</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Odie</Grid>
                    <Grid xs={3}>Okkie</Grid>
                    <Grid xs={3}>Ollie</Grid>
                    <Grid xs={3}>Ozzy</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
              <AccordionSummary aria-controls="panel16d-content" id="panel16d-header">
                <MKTypography>P</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Pablo</Grid>
                    <Grid xs={3}>Paco</Grid>
                    <Grid xs={3}>Pammy</Grid>
                    <Grid xs={3}>Pavo</Grid>
                    <Grid xs={3}>Peppy</Grid>
                    <Grid xs={3}>Pepsi</Grid>
                    <Grid xs={3}>Perry</Grid>
                    <Grid xs={3}>Picolo</Grid>
                    <Grid xs={3}>Piet</Grid>
                    <Grid xs={3}>Pika</Grid>
                    <Grid xs={3}>Pinda</Grid>
                    <Grid xs={3}>Pinkeltje</Grid>
                    <Grid xs={3}>Pinky</Grid>
                    <Grid xs={3}>Pipo</Grid>
                    <Grid xs={3}>Pino</Grid>
                    <Grid xs={3}>Plukkie</Grid>
                    <Grid xs={3}>Prince</Grid>
                    <Grid xs={3}>Puk</Grid>
                    <Grid xs={3}>Punky</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
              <AccordionSummary aria-controls="panel17d-content" id="panel17d-header">
                <MKTypography>Q</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Quazy</Grid>
                    <Grid xs={3}>Queeny</Grid>
                    <Grid xs={3}>Quibus</Grid>
                    <Grid xs={3}>Quicky</Grid>
                    <Grid xs={3}>Quilo</Grid>
                    <Grid xs={3}>Quincy</Grid>
                    <Grid xs={3}>Quinty</Grid>
                    <Grid xs={3}>Quozy</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel18'} onChange={handleChange('panel18')}>
              <AccordionSummary aria-controls="panel18d-content" id="panel18d-header">
                <MKTypography>R</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Rambo</Grid>
                    <Grid xs={3}>Raya</Grid>
                    <Grid xs={3}>Redbull</Grid>
                    <Grid xs={3}>Reggie</Grid>
                    <Grid xs={3}>Ricky</Grid>
                    <Grid xs={3}>Rocco</Grid>
                    <Grid xs={3}>Rocky</Grid>
                    <Grid xs={3}>Roxy</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel19'} onChange={handleChange('panel19')}>
              <AccordionSummary aria-controls="panel19d-content" id="panel19d-header">
                <MKTypography>S</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Sammy</Grid>
                    <Grid xs={3}>Sizzle</Grid>
                    <Grid xs={3}>Sjason</Grid>
                    <Grid xs={3}>Sjimmie</Grid>
                    <Grid xs={3}>Skippy</Grid>
                    <Grid xs={3}>Snoob</Grid>
                    <Grid xs={3}>Snoopy</Grid>
                    <Grid xs={3}>Snowy</Grid>
                    <Grid xs={3}>Sonic</Grid>
                    <Grid xs={3}>Sony</Grid>
                    <Grid xs={3}>Sparky</Grid>
                    <Grid xs={3}>Spitit</Grid>
                    <Grid xs={3}>Spykie</Grid>
                    <Grid xs={3}>Summer</Grid>
                    <Grid xs={3}>Sunny</Grid>
                    <Grid xs={3}>Suzy</Grid>
                    <Grid xs={3}>Stevie</Grid>
                    <Grid xs={3}>Sweety</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel20'} onChange={handleChange('panel20')}>
              <AccordionSummary aria-controls="panel20d-content" id="panel20d-header">
                <MKTypography>T</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Tijs</Grid>
                    <Grid xs={3}>Tiki</Grid>
                    <Grid xs={3}>Timmie</Grid>
                    <Grid xs={3}>Timo</Grid>
                    <Grid xs={3}>Tjiko</Grid>
                    <Grid xs={3}>Tommy</Grid>
                    <Grid xs={3}>Turbo</Grid>
                    <Grid xs={3}>Tweedle</Grid>
                    <Grid xs={3}>Tweek</Grid>
                    <Grid xs={3}>Tweety</Grid>
                    <Grid xs={3}>Twizzle</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel21'} onChange={handleChange('panel21')}>
              <AccordionSummary aria-controls="panel21d-content" id="panel21d-header">
                <MKTypography>U</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Uckie</Grid>
                    <Grid xs={3}>Uerika</Grid>
                    <Grid xs={3}>Ukkie</Grid>
                    <Grid xs={3}>Ulke</Grid>
                    <Grid xs={3}>Unox</Grid>
                    <Grid xs={3}>Uppie</Grid>
                    <Grid xs={3}>Usmo</Grid>
                    <Grid xs={3}>Uster</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel22'} onChange={handleChange('panel22')}>
              <AccordionSummary aria-controls="panel22d-content" id="panel22d-header">
                <MKTypography>V</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Victoria</Grid>
                    <Grid xs={3}>Vlekkie</Grid>
                    <Grid xs={3}>Vinnie</Grid>
                    <Grid xs={3}>Vito</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel23'} onChange={handleChange('panel23')}>
              <AccordionSummary aria-controls="panel23d-content" id="panel23d-header">
                <MKTypography>W</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Wibi</Grid>
                    <Grid xs={3}>Wicky</Grid>
                    <Grid xs={3}>Wiezie</Grid>
                    <Grid xs={3}>Winnie</Grid>
                    <Grid xs={3}>Woody</Grid>
                    <Grid xs={3}>Wizzy</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel24'} onChange={handleChange('panel24')}>
              <AccordionSummary aria-controls="panel24d-content" id="panel24d-header">
                <MKTypography>X</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Xander</Grid>
                    <Grid xs={3}>Xandrie</Grid>
                    <Grid xs={3}>Xantie</Grid>
                    <Grid xs={3}>Xanty</Grid>
                    <Grid xs={3}>Xena</Grid>
                    <Grid xs={3}>Xenos</Grid>
                    <Grid xs={3}>Xeny</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel25'} onChange={handleChange('panel25')}>
              <AccordionSummary aria-controls="panel25d-content" id="panel25d-header">
                <MKTypography>Y</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Yara</Grid>
                    <Grid xs={3}>Yno</Grid>
                    <Grid xs={3}>Yoshi</Grid>
                    <Grid xs={3}>Yoyo</Grid>
                    <Grid xs={3}>Yunai</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel26'} onChange={handleChange('panel26')}>
              <AccordionSummary aria-controls="panel26d-content" id="panel26d-header">
                <MKTypography>Z</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  <Grid container justifyContent="center" textAlign="center">
                    <Grid xs={3}>Zaza</Grid>
                    <Grid xs={3}>Zazou</Grid>
                    <Grid xs={3}>Zippo</Grid>
                    <Grid xs={3}>Zomie</Grid>
                  </Grid>
                </MKTypography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ParkietenNamen;
