/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";

function Vrienden() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
              Onze Vrienden
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container spacing={2} justifyContent="center" textAlign="center">
          <Grid item xs={12}>
            <MKTypography variant="h2" mt={"1em"}>
              Vrienden
            </MKTypography>
          </Grid>
          <Grid item xs={12}>
          <a href="http://www.parkietenfreak.nl/" target="_blank">
          <img src="http://www.parkietenfreak.nl/banners/parkietenfreakbanner1.jpg" width="350" alt="Parkietenfreak" border="0" />
          </a>
          </Grid>
          <Grid item xs={12}>
          <a href="http://www.parkieten-online.nl/" target="_blank">
          <img src="http://www.parkieten-online.nl/234banner.jpg" width="350" alt="Parkietenfreak" border="0" />
          </a>
          </Grid>
          <Grid item xs={12}>
          <a href="http://www.parkieten-oosterhout.nl/" target="_blank">
          <img src="http://www.parkieten-oosterhout.nl/Foto/banner/Parkietenfun_Banner.jpg" width="350" alt="Parkietenfreak" border="0" />
          </a>
          </Grid>
          <Grid item xs={12}>
          <MKTypography variant="h2" mt={"1em"}>
            Onze Banner
          </MKTypography>
          </Grid>
          <Grid item xs={12}>
            <img src="http://www.parkieten-oosterhout.nl/Foto/banner/banner_parkieten-oosterhout.jpg" width="350px" alt="Banner www.parkieten-oosterhout.nl" onclick=""  />
            <p>Wilt u onze banner op uw site?</p>
            <p>Link: http://www.parkieten-oosterhout.nl/Foto/banner/banner_parkieten-oosterhout.jpg</p>
          </Grid>
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Vrienden;
