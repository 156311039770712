/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Foto1 from "assets/images/vogels/Vogels-41.jpg"

function Gedrag() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
              Gedrag
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
              Veren
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
              De parkiet is per dag heel wat tijd bezig met het verzorgen van z'n veren, Hij poetst zijn veren door ze één voor één door zijn snavel te trekken en glad te maken, zo verdwijnen ook de schilfertjes en het vuil tussen de veren.
            </p>
          </Grid>
          
          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Snavel
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
              De parkiet is per dag heel wat tijd bezig met het verzorgen van z'n veren, Hij poetst zijn veren door ze één voor één door zijn snavel te trekken en glad te maken, zo verdwijnen ook de schilfertjes en het vuil tussen de veren.
            </p>
          </Grid>
          
          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Heftig ja knikken
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            De parkiet schud met de kop net als of hij ja knikt, dat is een een teken dat hij blij is en het erg naar zijn zin heeft. Vaak gaat het samen met het maken van geluidjes
            </p>
          </Grid>
          
          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Knarsen
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Als de parkiet knarst met z'n snavel is dat een teken dat hij erg tevreden en ontspannen is, ze rispen de zaadjes vanuit de krop en vermalen ze wat een knarsend geluid geeft.
            </p>
          </Grid>
          
          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Pootjes strekken
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Als wij een tijdje stil hebben gezeten moeten we even de benen strekken,zo is dat bij de parkiet ook. Hij strekt zo af en toe een pootje met tegelijkertijd een vleugel naar achteren, zo maakt hij alles na een lange zit weer lekker soepel.
            </p>
          </Grid>
          
          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Optillen van de vleugels
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Dat doet de parkiet ook regelmatig, bijvoorbeeld als het een beetje te warm wordt of wanneer hij tevreden is. Als hij ze zijwaarts uitstrekt is dat wanneer hij een popje aan de haak wil slaan (versieren dus), op die manier hoopt hij indruk te maken.
            </p>
          </Grid>
          
          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Schudden van de veren
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Doet de parkiet om vuil en stof kwijt te raken, of om na een badje zijn veren weer netjes te krijgen Ook wanneer de parkiet met iets nieuw begint, schrikt, onzeker is, of gezellig heeft zitten snavelen, schud hij even met de veren.
            </p>
          </Grid>
          
          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Rusten
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Na al dat spelen, poetsen en vliegen heeft de parkiet natuurlijk ook rust nodig, hij zit dan lekker op z'n stokjes met de oogjes half dicht. Tijdens het slapen zit de parkiet meestal op één pootje, dat is ook een teken dat de parkiet zich goed voelt en het fijn heeft. Vaak legt hij zijn kopje tussen de rugveren, zo zie je maar dat de parkiet super lenig is want hij kan z'n kopje wel 180 graden draaien.
            </p>
          </Grid>
          
          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Geeuwen
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Net als wij moeten ook parkieten wel eens geeuwen, meestal is de parkiet dan moe of heeft het een beetje benauwd, zorg dan voor wat frisse lucht in kamer. Bij mensen is het vaak dat geeuwen aanstekelijk is, dat is net zo bij parkieten als er een begint moet de ander ook geeuwen.
            </p>
          </Grid>
          
          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Niezen
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Ook een parkiet moet wel eens niezen, door te niezen worden de neusgaten gereinigd. Dat de parkiet af en toe niest is dus heel normaal, alleen als er veel vocht uitkomt is hij verkouden.
            </p>
          </Grid>

          <Grid item xs={12} lg={4}>
            <img src={Foto1} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Snavelen
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Wanneer 2 parkieten elkaar lief vinden gaan ze vaak snavelen, dit is een teken dat ze elkaar accepteren. De snaveltje steken iets in elkaar en vaak gaat het gepaard met elkaar te voeren, ze halen dan de zaadjes uit de krop en geven het via de snavel aan de ander.
            </p>
          </Grid>
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Gedrag;
