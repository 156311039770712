/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Foto1 from "assets/images/Op soort/Jonge vogels/Vogels-23.jpg"
import Foto2 from "assets/images/Op soort/Jonge vogels/Vogels-31.jpg"
import Foto3 from "assets/images/vogels/Vogels-141.jpg"
import Foto4 from "assets/images/vogels/Vogels-68.jpg"
import Foto5 from "assets/images/Zelf/Zelf-10.jpg"

function Valkparkiet() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
              De Valkparkiet
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <img src={Foto1} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
              Geschiedenis
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
              De valkparkiet (Nymphicus hollandicus) is een in Nederland en België vrij populaire volière- en kooivogel, die oorspronkelijk uit Australië afkomstig is. John Gould was de eerste die valkparkieten beschreef. Hij vermeldde in zijn boek The birds of Australia (1839) dat de grond in Australië bezaaid was met valkparkieten en dat op takken van bomen nog eens honderden vogels zaten. Hij gaf de vogel de wetenschappelijke naam Palaeornis novae-hollandiae, oude vogel uit Nieuw-Holland. In 1850 werden de eerste Europese valkparkieten geboren in Duitsland. In Nederland werden in 1865 de eerste valkparkieten gekweekt, en in België in 1870. Pas in 1965 kreeg de valkparkiet zijn huidige wetenschappelijke naam: Nymphicus hollandicus. 'Nymphicus' betekent: gelijkend op een nimf, en 'hollandicus': van Holland (Nieuw Holland is een oude benaming voor een deel van Australië). Recent onderzoek van mitochondriaal DNA heeft aangetoond dat de valkparkiet een kleine kakatoe is, en het meest verwant met de donkergekleurde kakatoes van het geslacht Calyptorhynchus.
            </p>
          </Grid>

          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Uiterlijk
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            De wildkleur valkparkiet is de meest voorkomende en heeft de volgende kenmerken: Grijs met witte vlekken op de vleugels, een gele kop met een gele kuif, en oranje oorvlekken. Er zijn ook andere kleurvariëteiten gekweekt, bijvoorbeeld de lutino, opaline (gepareld), grijs en wit, en albino. Het geslachtsverschil is weinig opvallend - de mannetjes ('man') hebben een gele kop en kuif, het vrouwtje ('pop') heeft een minder gele kop, wat vooral bij onderlinge vergelijking van een paartje opvalt, en ook niet bij alle kleurvarianten zichtbaar is. Kenners kunnen het eerder aan bepaalde gedragingen zien, mannetjes vertonen machogedrag. Ze wegen 80 à 110 gram en zijn ongeveer 30 tot 33 centimeter lang (kop-staart).
            Valkparkieten kunnen goed als huisdier worden gehouden maar aspirant-valkparkiethouders moeten zich realiseren dat vooral de mannen erg luidruchtig kunnen zijn en dat de veren stof produceren, er zijn speciale onderdonsveren die tot een soort poeder uit elkaar vallen, net als bij duiven. Vooral als ze in de eerste 8 levensweken veel met mensen in contact komen kunnen ze erg tam worden. Valkparkieten worden gemiddeld tussen de 15-20 jaar oud maar je hebt natuurlijk uitzonderingen. Ze komen in gevangenschap relatief gemakkelijk tot broeden.
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto2} alt="" class="imgright" width="100%" />
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
              Dieet
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
              Ze eten voornamelijk grof parkietenzaad, daarnaast wordt aangeraden om ook met grote regelmaat groenvoer aan te bieden. Er moet ook dagelijks vers drinkwater beschikbaar zijn. Naast grof parkietzaad bestaan er ook totaalvoeders in de vorm van pellets. Sommigen vinden pellets minder gewenst omdat ze de functie van de krop overbodig maken. Anderen zijn van mening dat pellets een volwaardiger voer zijn. Om de krop goed te laten werken heeft de parkiet maagkiezel nodig in de kooi, waarvan hij er af en toe een naar binnen werkt. Dit is namelijk nodig voor de vermaling van het eten in de krop. . Wanneer men met valkparkieten wil kweken dus wanneer een mannetje en een vrouwtje bij elkaar worden gezet en er jongen worden verwacht, kan er eivoer worden bijgegeven. Dit is normaal gesproken niet nodig om de vogels gezond te houden.
            </p>
          </Grid>
          
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
              Kooi
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Minstens 40x50x60 cm, en dan nog wel af en toe buiten de kooi laten vliegen. Tralies dienen horizontaal te lopen om klauteren mogelijk te maken. Natuurlijk is het beter om een ruimer onderkomen voor de valkparkiet te kiezen zodat deze volledig tot zijn recht komt. Een volière, buiten of binnen, is ideaal voor een valkparkiet, die dan te allen tijde zijn vleugels kan strekken.
            </p>
            <MKTypography variant="h2" mb={"1em"}>
            Als huisdier
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            De valkparkiet is goed als huisdier te houden, als het stof en het lawaai geen bezwaar zijn. Ze kunnen erg tam worden en hebben meestal een zachtaardig karakter. Ook hebben ze er geen problemen mee geluiden als fluitjes, of woordjes te imiteren. Let er wel op dat een angstige of boze valkparkiet wel hard kan bijten als hij dat wil. Valkparkieten zijn zelfs zo schuchter dat kleinere, maar agressievere vogels zoals de grasparkiet ze soms het leven zuur kunnen maken en ze zelfs kunnen verwonden. Probeer altijd een jonge handtamme valkparkiet te kopen; een oudere valkparkiet is moeilijker en soms helemaal niet tam te maken. Kijk ook of de valkparkiet een ring om zijn poot heeft; deze kan links of rechts worden aangebracht. Zo kan men met zekerheid zeggen of de vogel inderdaad nog jong is. Tam maken begint met de vogel uit de hand te laten eten. De valkparkiet is van nature sociaal en nieuwsgierig, en zal als hij zich eenmaal op zijn gemak voelt zelf initiatief nemen. Er zijn ook kwekerijen waar de vogel (soms tegen extra betaling) handtam wordt gemaakt. Kortwieken maakt het tam maken meestal eenvoudiger omdat de vogel zich niet zo makkelijk meer aan zijn baasje kan onttrekken. Men heeft dan in ieder geval tot de volgende rui de tijd.
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto3} alt="" class="imgright" width="100%" />
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Een zieke valkparkiet
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Een zieke valkparkiet is vaak te herkennen aan een ander gedrag dan normaal. Hij zit bijvoorbeeld bol in de veren of slaapt zelfs de hele dag. Een zieke valkparkiet slaapt daarnaast over het algemeen op twee poten in plaats van een. Ga met een zieke valkparkiet zo snel mogelijk naar de dierenarts om een diagnose te laten stellen. Mocht de valkparkiet namelijk de zogenaamde papegaaienziekte hebben dan is behandeling bij de mensen die met de besmette dieren in aanraking zijn geweest soms ook noodzakelijk. De meeste ziektes van valkparkieten zijn simpel te genezen.
            </p>
          </Grid>

          <Grid item xs={12} lg={4}>
            <img src={Foto4} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
              Kweek
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Het kweken van Valkparkieten is niet zo erg lastig, het is wel lastig om goede koppels te vinden. De mannetjes hebben feller gekleurde rode wangen dan de pop, al is dit niet bij alle kleurmutaties te zien. Valkparkieten hebben genoeg aan een hoog broedblok (25x25x35)met een opening van 8 cm doorsnede, let er wel op dat de onderkant is uitgehold, zodat de eieren niet van elkaar vandaan gaan liggen.
            </p>
            <p style={{marginBottom:"1em"}}>
            Een pop legt 3-9 witte eieren en broedt 18-21 dagen waarin zij wordt bijgestaan door het mannetje. Na 4-5 weken vliegen de eerste jongen uit en na 7-8 weken zijn zij zelfstandig. Na 9 maanden zijn Valkparkieten geslachtsrijp, maar aangeraden wordt om langer te wachten om ze voor de kweek in te zetten. Het mannetje krijgt ook pas later zijn felle kleuren.
            </p>
          </Grid>

          <Grid item sx={1} lg={2}></Grid>
          <Grid item sx={10} lg={8}>
            <img src={Foto5} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item sx={1} lg={2}></Grid>
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Valkparkiet;
