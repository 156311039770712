/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import Foto1 from "assets/images/vogels/Vogels-23.jpg"
import Foto2 from "assets/images/Zelf/Zelf-81.jpg"
import Foto3 from "assets/images/Sisal.jpg"
import Foto4 from "assets/images/Speeltjes.jpg"
import Foto5 from "assets/images/Speeltuin.jpg"
import Foto6 from "assets/images/Speelboom.jpg"

function Speeltjes() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
              Speeltjes & extra's voor de parkiet
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container spacing={3} justifyContent="center" textAlign="center" mt="1em">
          
          <Grid item xs={12} lg={6} mb={"3em"}>
            <CenteredBlogCard
              image={Foto1}
              title="Hangplek"
              description={
                <p>
                Deze hangplek is heel makkelijk te maken, misschien heb je net als ik wel hout over van je zelfgemaakte speeltuin.
                Je neemt een stuk van een bezemsteel en een aantal stokken met een dikte van zo'n 11 cm beide kan je natuurlijk zo lang maken als je zelf wenst.
                Je boort een aantal gaten met de juiste maat door de bezemsteel en daar komen de zitstokjes doorheen.
                Als ze goed vast zitten is het niet gelijk nodig ze daarbij nog vast te lijmen, als je het wel wil let dan op dat er geen lijmresten op de zitstokken terecht komt.
                Ik heb er zelf nog wat schroefoogjes aan vast gemaakt zodat je er wat klein speelgoed aan vast kan maken.
                Om de hangplek op te kunnen hangen zit er van boven een schroefoogje aan met een stukje ketting, maar in plaats van ketting kan je ook gemakkelijk een stuk touw gebruiken. Let er wel op dat het niet makkelijk kapot te knagen is, want dan ligt de zitplek plus de parkiet op de grond.
                </p>
              }
              action={{
                type: "none",
              }}
            />
          </Grid>

          <Grid item xs={12} lg={6} mb={"3em"}>
            <CenteredBlogCard
              image={Foto2}
              title="Creatief met wilgentakken"
              description={
                <>
                  <p>
                  Ik had nog genoeg wilgentakken over en wilde er iets van maken.
                  Dit is mijn eerste creatie met takken dus het was even uitproberen.
                  Benodigdheden zijn touw(bv sisal, takken(bv wilg)en gereedschap.
                  Zaag de takken op de lengte af zoals jij ze wil hebben, boor er gaten in zodat het touw er door heen kan, zo rijg je alles aan elkaar.
                  </p>
                  <p>
                  Je kan het helemaal naar eigen inzicht maken dit is de basis en het is eigenlijk heel eenvoudig.
                  Hier een leuke zitplek/schommel voor de parkieten gemaakt op de manier zoals boven aan beschreven staat.
                  </p>
                  <p>
                  Een hanger, op dezelfde manier gemaakt als de schommel maar dan anders vastgemaakt. Zo zie je met dezelfde techniek zijn allerlei variatie's mogelijk.
                  </p>
                </>
              }
              action={{
                type: "none",
              }}
            />
          </Grid>

          <Grid item xs={12} lg={6} mb={"3em"}>
            <CenteredBlogCard
              image={Foto3}
              title="Sisal speeltje"
              description={
                  <p>
                  Heel goedkoop en enorm leuk voor je parkiet om mee bezig te zijn. Ze knabbelen en trekken er aan en zijn er een tijdje mee zoet.
                  Je hoeft er niet bang voor te zijn dat het sisaltouw slecht is voor je parkiet want dat is het niet, sisaltouw is heel geschikt om mee te spelen.
                  Een bolletje sisaltouw is in veel zaken te koop bv bij bouwmarkten, hema, action, etc. Het enigste wat je nodig hebt is sisaltouw en een schaar, en het is heel makkelijk te maken.
                  Je knipt van een stuk sisaltouw sliertjes (je mag zelf kiezen hoe lang of kort je ze maakt)
                  Als je genoeg reepjes hebt neem je ze bij elkaar en pak je opnieuw een stuk sisaltouw
                  Dit stuk moet lang genoeg zijn om een paar knopen in te kunnen leggen en het speeltje mee op te kunnen hangen.
                  Met dit stuk touw bind je de sliertjes in het midden vast, leg er een goede knoop op zodat het stevig vast zit.
                  </p>
              }
              action={{
                type: "none",
              }}
            />
          </Grid>

          <Grid item xs={12} lg={6} mb={"3em"}>
            <CenteredBlogCard
              image={Foto4}
              title="Speelgoed"
              description={
                  <p>
                  Hang ook wat speeltjes in de kooi, natuurlijk wel zo dat de parkiet nog genoeg ruitmte heeft om te bewegen. Wat jouw parkiet het leukste speelgoed vind hangt er helemaal vanaf, wat de ene parkiet geweldig vind laat de ander links liggen. Na een poosje zie je vanzelf wat je parkiet het liefst heeft, bij ons zijn ze gek op dingen die draaien en waar ze in kunnen gaan hangen. Wissel de speeltjes zo af en toe is af, je hebt ze in alle soorten en maten van touw, hout, acryl enz
                  </p>
              }
              action={{
                type: "none",
              }}
            />
          </Grid>

          <Grid item xs={12} lg={6} mb={"3em"}>
            <CenteredBlogCard
              image={Foto5}
              title="Een speeltuin"
              description={
                  <p>
                  Alweer een tijdje geleden zag ik in een dierenwinkel een leuk speeltuintje voor m'n parkieten. Hey dat is leuk, maar ik schrok van de prijs en dan heb je alleen nog maar een paar stokjes met een schommeltje en een trapje. Natuurlijk heb ik veel voor mijn lieve parkieten over maar ik heb het daar toch maar laten liggen... Dat kan je zelf ook wel maken dacht ik. Zo ben ik op het idee gekomen, ja het hout moet je wel kopen maar van een paar meter kun je heel wat maken. En het leukste er van is dat je zelf kunt kiezen hoe de speeltuin er uit komt te zien en je bent creatief bezig.
                  </p>
              }
              action={{
                type: "none",
              }}
            />
          </Grid>

          <Grid item xs={12} lg={6} mb={"3em"}>
            <CenteredBlogCard
              image={Foto6}
              title="Een speelboom"
              description={
                  <>
                    <p>
                      Hoi allemaal! Ik zal mezelf even voorstellen. ik ben een grasparkietje van 15 weken jong. Volgens mijn baasje ben ik ontzettend nieuwsgierig en bijdehand. Persoonlijk vind ik deze omschrijving behoorlijk overdreven... Ik ben gewoon een zeer leergierig en ondernemend vogeltje. Zeg nu zelf, daar is toch helemaal niets mis mee???
                    </p>
                    <p>
                      Op de foto zie je mij op m'n favoriete plekje: de speelboom! Deze boom is door mijn baasje handig in elkaar gezet en ik breng hier vele uurtjes van de dag door.Klimmend, klauterend, spelend en soms ook slapend. Het allerliefst zit ik naast mijn kameraadje "nepparkiet" Bij hem kan ik te allen tijde mijn hart luchten. Ik praat dan honderduit tegen hem in parkietentaal of ik ga heel dicht tegen hem aan zitten en fluit dan zachtjes mooie liedjes in zijn oor. Na afloop hiervan overlaad ik hem met kusjes, omdat hij zo fijn naar mij heeft geluisterd! Zoals jullie kunnen lezen, vermaak ik mezelf dus opperbest in m'n speelboom.
                    </p>
                    <p>
                      Wil je ook dat jouw baasje een speelboom voor je maakt? Dan volgt hier mijn wijze raad:
                      "een speelboom is beslist de moeite waard, dus sta erop dat je baasje dit klusje voor je klaart!"
                    </p>
                    
                    <MKTypography variant="h2" mt={"1em"}>
                      Benodigdheden
                    </MKTypography>
                    <p>
                    Lange, dikke tak (doorsnee van ong. 6 cm.) met een paar dikke zijtakken, voorbeelden van geschikte takken zijn de eik, els, vlier, linde, populier, kastanje en wilg. Een aantal extra zijtakken (je kunt eventueel ook een paar houten zitstokken gebruiken).
                    </p>
                    <ul style={{marginLeft:"2em", marginTop:"1em"}}>
                      <li>1 stevige bloempot/emmer met een doorsnede van ong. 30 cm.</li>
                      <li>1 grote zak vogelzand</li>
                      <li>2 rolletjes bloemdraad</li>
                      <li>2 bosjes ongeverfde raffia</li>
                    </ul>
                    
                    <MKTypography variant="h2" mt={"1em"}>
                    Werkwijze
                    </MKTypography>
                    <p>
                    Boen de takken goed schoon met heet water en laat ze drogen. Zet de tak in het midden van de bloempot/emmer en giet de zak vogelzand erbij zodat het geheel niet om kan vallen. Bevestig met bloemdraad de extra zijtakken/houten zitstokken vast aan de stam. Omwikkel het bloemdraad met raffia, zodat de parkiet zich niet kan verwonden. Uiteraard wordt de speelboom pas echt leuk door er wat speelgoed in te hangen!
                    Veel succes en plezier toegewenst
                    </p>
                  </>
              }
              action={{
                type: "external",
                route: "https://www.parkietenfreak.nl/r-parkietenboom.php",
                color: "info",
                label: "Meer voorbeelden"
              }}
            />
          </Grid>


        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Speeltjes;
