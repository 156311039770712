/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";
import k1 from "assets/images/Kleurslagen/lichtgroen.jpg";
import k2 from "assets/images/Kleurslagen/donkergroen.jpg";
import k3 from "assets/images/Kleurslagen/olijfgroen.jpg";
import k4 from "assets/images/Kleurslagen/grijsgroen.jpg";
import k5 from "assets/images/Kleurslagen/hemelsblauw.jpg";
import k6 from "assets/images/Kleurslagen/kobaltblauw.jpg";
import k7 from "assets/images/Kleurslagen/mauve.jpg";
import k8 from "assets/images/Kleurslagen/grijs.jpg";
import k9 from "assets/images/Kleurslagen/violet.jpg";
import k10 from "assets/images/Kleurslagen/geelmasker-hemelsblauw.jpg";
import k11 from "assets/images/Kleurslagen/opaline-cinnamon-lichtgroen.jpg";
import k12 from "assets/images/Kleurslagen/opaline-donkergroen.jpg";
import k13 from "assets/images/Kleurslagen/opaline-olijfgroen.jpg";
import k14 from "assets/images/Kleurslagen/opaline-grijsgroen.jpg";
import k15 from "assets/images/Kleurslagen/opaline-hemelsblauw.jpg";
import k16 from "assets/images/Kleurslagen/opaline-kobaltblauw.jpg";
import k17 from "assets/images/Kleurslagen/opaline-mauve.jpg";
import k18 from "assets/images/Kleurslagen/opaline-grijs.jpg";
import k19 from "assets/images/Kleurslagen/opaline-violet.jpg";
import k20 from "assets/images/Kleurslagen/opaline-geelmaskergrijs.jpg";
import k21 from "assets/images/Kleurslagen/cinnamon-lichtgroen.jpg";
import k22 from "assets/images/Kleurslagen/cinnamon-donkergroen.jpg";
import k23 from "assets/images/Kleurslagen/opaline-olijfgroen.jpg";
import k24 from "assets/images/Kleurslagen/cinnamon-grijsgroen.jpg";
import k25 from "assets/images/Kleurslagen/cinnamon-hemelsblauw.jpg";
import k26 from "assets/images/Kleurslagen/cinnamon-kobaltblauw.jpg";
import k27 from "assets/images/Kleurslagen/cinnamon-mauve.jpg";
import k28 from "assets/images/Kleurslagen/cinnamon-grijs.jpg";
import k29 from "assets/images/Kleurslagen/cinnamon-violet.jpg";
import k30 from "assets/images/Kleurslagen/geelmasker-cinnamon-grijs.jpg";
import k31 from "assets/images/Kleurslagen/grijsvleugel-grijsgroen.jpg";
import k32 from "assets/images/Kleurslagen/grijsvleugel-hemelsblauw.jpg";
import k33 from "assets/images/Kleurslagen/geelmasker-grijsvleugel-grijs.jpg";
import k34 from "assets/images/Kleurslagen/opaline-cinnamon-lichtgroen.jpg";
import k35 from "assets/images/Kleurslagen/opaline-cinnamon-hemelsblauw.jpg";
import k36 from "assets/images/Kleurslagen/geelmasker-opaline-cinnamon-hemelsblauw.jpg";
import k37 from "assets/images/Kleurslagen/opaline-grijsvleugel-grijsgroen.jpg";
import k38 from "assets/images/Kleurslagen/opaline-grijsvleugel-hemelsblauw.jpg";
import k39 from "assets/images/Kleurslagen/geelmask-opal-grijsvleugel-grijs.jpg";
import k40 from "assets/images/Kleurslagen/recessief-bont-kobalt.jpg";
import k41 from "assets/images/Kleurslagen/dominant-bont-witbuik-hemelsbl.jpg";
import k42 from "assets/images/Kleurslagen/dominant-bont-metband-lichtgr.jpg";
import k43 from "assets/images/Kleurslagen/witvleugel-violet.jpg";
import k44 from "assets/images/Kleurslagen/regenboog.jpg";
import k45 from "assets/images/Kleurslagen/overgoten-groen.jpg";
import k46 from "assets/images/Kleurslagen/overgoten-blauw.jpg";
import k47 from "assets/images/Kleurslagen/gezoomd-groen.jpg";
import k48 from "assets/images/Kleurslagen/gezoomd-blauw.jpg";
import k49 from "assets/images/Kleurslagen/opaline-gezoomd-groen.jpg";
import k50 from "assets/images/Kleurslagen/opaline-gezoomd-blauw.jpg";
import k51 from "assets/images/Kleurslagen/fallow-groen.jpg";
import k52 from "assets/images/Kleurslagen/gele-bruinvleugel.jpg";
import k53 from "assets/images/Kleurslagen/witte-bruinvleugel.jpg";
import k54 from "assets/images/Kleurslagen/lutino.jpg";
import k55 from "assets/images/Kleurslagen/geelmasker-ino.jpg";
import k56 from "assets/images/Kleurslagen/albino.jpg";
import k57 from "assets/images/Kleurslagen/oppaline-cinn-grijsgr-kuif.jpg";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Kleurslagen() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);


  const kleuren = [
    { name: "Lichtgroen",                               image: k1  },
    { name: "Donkergroen",                              image: k2  },
    { name: "Olijfgroen",                               image: k3  },
    { name: "Grijsgroen",                               image: k4  },
    { name: "Hemelsblauw",                              image: k5  },
    { name: "Kobaltblauw",                              image: k6  },
    { name: "Mauve",                                    image: k7  },
    { name: "Grijs",                                    image: k8  },
    { name: "Violet",                                   image: k9  },
    { name: "Geelmasker Hemelsblauw",                   image: k10  },
    { name: "Opaline Lichtgroen",                       image: k11 },
    { name: "Opaline Donkergroen",                      image: k12 },
    { name: "Opaline Olijfgroen",                       image: k13 },
    { name: "Opaline Grijsgroen",                       image: k14 },
    { name: "Opaline Hemelsblauw",                      image: k15 },
    { name: "Opaline Kobaltblauw",                      image: k16 },
    { name: "Opaline Mauve",                            image: k17 },
    { name: "Opaline Grijs",                            image: k18 },
    { name: "Opaline Violet",                           image: k19 },
    { name: "Opaline Geelmaskergrijs",                  image: k20 },
    { name: "Cinnamon Lichtgroen",                      image: k21 },
    { name: "Cinnamon Donkergroen",                     image: k22 },
    { name: "Opaline Olijfgroen",                       image: k23 },
    { name: "Cinnamon Grijsgroen",                      image: k24 },
    { name: "Cinnamon Hemelsblauw",                     image: k25 },
    { name: "Cinnamon Kobaltblauw",                     image: k26 },
    { name: "Cinnamon Mauve",                           image: k27 },
    { name: "Cinnamon Grijs",                           image: k28 },
    { name: "Cinnamon Violet",                          image: k29 },
    { name: "Geelmasker Cinnamon Grijs",                image: k30 },
    { name: "Grijsvleugel Grijsgroen",                  image: k31 },
    { name: "Grijsvleugel Hemelsblauw",                 image: k32 },
    { name: "Geelmasker Grijsvleugel Grijs",            image: k33 },
    { name: "Opaline Cinnamon Lichtgroen",              image: k34 },
    { name: "Opaline Cinnamon Hemelsblauw",             image: k35 },
    { name: "Geelmasker Opaline Cinnamon Hemelsblauw",  image: k36 },
    { name: "Opaline Grijsvleugel Grijsgroen",          image: k37 },
    { name: "Opaline Grijsvleugel Hemelsblauw",         image: k38 },
    { name: "Geelmasker Opaline Grijsvleugel Grijs",    image: k39 },
    { name: "Recessief Bont Kobalt",                    image: k40 },
    { name: "Dominant Bont Witbuik Hemelsblauw",        image: k41 },
    { name: "Dominant Bont Metband lichtgroen",         image: k42 },
    { name: "Witvleugel Violet",                        image: k43 },
    { name: "Regenboog",                                image: k44 },
    { name: "Overgoten Groen",                          image: k45 },
    { name: "Overgoten Blauw",                          image: k46 },
    { name: "Gezoomd Groen",  	                        image: k47 },
    { name: "Gezoomd Blauw",  	                        image: k48 },
    { name: "Opaline Gezoomd Groen",                    image: k49 }, 
    { name: "Opaline Gezoomd Blauw",                    image: k50 },
    { name: "Fallow Groen",                             image: k51 },
    { name: "Gele Bruinvleugel",                        image: k52 },
    { name: "Witte Bruinvleugel",                       image: k53 },
    { name: "Lutino",                                   image: k54 },
    { name: "Geelmasker Ino",                           image: k55 },
    { name: "Albino",                                   image: k56 },
    { name: "Oppaline Cinnamon Grijsgroen Kuif",        image: k57 }
  ]

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
              Kleurslagen
            </MKTypography> 
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} mb="1em">
            Hier zie je de standaard kleurslagen die er van de grasparkieten zijn.
            Natuurlijk zijn er veel meer combinatie's, die uit kruisingen voortgekomen zijn uit de diverse kleurenslagen die je hieronder ziet.
            Het totaal aantal standaard kleurslagen zit nu op 57 verschillende.
            Onderstaande foto's zijn van <a href="http://www.parkieten-online.nl" tagert="_blank">parkieten-online.nl</a> gehaald
          </Grid>
          { kleuren.map(item => {
            return (
              <Grid item xs={12} lg={3} mb={"3em"}>
                <CenteredBlogCard
                  image={item.image}
                  title={item.name}
                  description=""
                  action={{
                    type: "none",
                  }}
                />
              </Grid>
            )
          })
        }
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Kleurslagen;
