/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import * as React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Foto1 from "assets/images/vogels/Vogels-126.jpg"
import Foto2 from "assets/images/Op soort/Jonge vogels/Vogels-26.jpg"
import Foto3 from "assets/images/Zelf/Zelf-26.jpg"
import Foto4 from "assets/images/vogels/Vogels-125.jpg"


// Accordion
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function Broeden() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
              Kweken en broeden
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Het broedseizoen
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Het broedseizoen voor grasparkieten in hun natuurlijke omgeving in Australië is afhankelijk van de beschikbaarheid van voldoende voedsel en water, dit zal aan het eind of kort na een regenseizoen zijn. Voor de grasparkieten die hier bij ons die in een buitenvolière zitten begint het broedseizoen eind maart - begin april (afhankelijk van het weer) tot september. Mensen die een kweekruimte hebben of waarvan de parkieten binnen zitten hebben de broedomstandigheden zelf in de hand wat betreft de verlichting en verwarming, waardoor er in principe het hele jaar door gebroed kan worden. Het wordt aangeraden om je pop niet meer dan twee keer per jaar een legsel te laten hebben. Het is ook niet zo dat u bepaalt wanneer de parkiet wil gaan broeden. Het is niet omdat het broedseizoen is, dat je parkieten er al automatisch klaar voor zijn. U moet uw parkieten voorbereiden op het broedseizoen.
            </p>
            <MKTypography variant="h2" mb={"1em"}>
            De voorbereiding
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            De grasparkieten bereid je voor door eerst te zorgen dat de daglengte overeenkomt met de natuurlijke daglengte aan het begin van een broedseizoen. Dit is ongeveer een 15 uur. Zorg ook dat je vogels goed, gezond, sterk en uitgerust zijn. Geeft kort voor de broedperiode dagelijks eivoer. Zorg ook dat ze voldoende kalk krijgen. Breng alle broedkooien en blokken op orde met eventueel een extra schoonmaakbeurt. En als er nog veranderingen aan de broedhokken of blokken of kooien moet gebeuren doe het dan voor je parkieten gaan broeden, zodat je ze niet meer moet storen tijdens het broeden. Zorg er ook voor dat je grasparkieten de goede leeftijd hebben om te broeden. Een popje moet minstens 1 jaar zijn en niet ouder dan 5 jaar en met een mannetje kan dat vanaf 10 maanden. Als je de goede voorbereidingen hebt gedaan kun je beginnen met broeden.
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto1} alt="" class="imgright" width="100%" />
          </Grid>

          <Grid item xs={12} lg={4}>
            <img src={Foto2} alt="" class="imgright" width="100%" />
            <img src={Foto3} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Het broeden
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Je parkieten zullen koppeltjes vormen en dan breekt de tijd aan om broedblokken (of nestkastjes) op te hangen. De afmetingen van een goed nestkastje zijn 15x15x25 cm met een invlieggat van 4 tot 6 cm diameter. Zorg er ook voor dat je één of twee nestkastjes meer hebt dan koppeltjes, om problemen te voorkomen. Parkieten broeden het liefste op een harde ondergrond dus bodembedekking is niet nodig. Wat wel belangrijk is, is een uitholling op de bodem, waarin het popje haar eitjes kan leggen zodat de eitjes niet weg kunnen rollen. Als u van in het begin nestcontrole doet, dan wennen de parkietjes daar snel aan en zal het geen problemen geven als je dan even kijkt terwijl ze broeden. Na het ophangen van de nestkastje begint ook het paren. Een enkele paring is voldoende om alle eitjes te bevruchten. Of de eitjes bevrucht zijn kun je na een vijftal dagen zien op twee manieren:
            </p>
            <ol style={{marginLeft: "1em"}}>
              <li>Je kan het aan de kleur zien: als de eitjes melkachtig wit zijn, zijn ze bevrucht. Blijven ze roze doorschijnend dan zijn ze onbevrucht.</li>
              <li>Je houdt het eitje tegen het licht. Als je dan rode lijntjes ziet lopen in het eitje (dat zijn de bloedvaatjes en het hartje) dan is het eitje bevrucht. Zie je alleen roze doorschijnend dan is het eitje niet bevrucht.</li>
            </ol>
            <p style={{marginBottom:"1em"}}>
            Het eerste eitje mag je een tiental dagen na de paring verwachten en daarna legt het popje om de dag een eitje. Een parkiet legt gemiddeld een vier tot acht eieren per legsel. Pas na het leggen van het tweede eitje begint het popje te broeden. Zij broedt dan ongeveer 21 dagen als het eerste kleintje geboren wordt. Daarna wordt er net zoals er eitjes gelegd zijn om de dag een kleintje geboren. Je kan de kleintjes al een paar uur voor ze uit het ei komen horen piepen. Na de geboorte van de kleintjes gooien de meeste popjes de lege eischalen gewoon het nestkastje uit (ook daar zijn er uitzonderingen). De eerste dagen worden de jongen gevoed door de moeder met kropmelk. Kropmelk is een eiwitrijke afscheiding van de kropwand. Na een weekje gaat dan ook het mannetje mee helpen met het voeden van de kuikentjes. En langzaam leren de kuikentjes dan ook om hard voedsel (zaden) te eten en uiteindelijk om geheel zelfstandig te eten. Na een viertal weken vliegen de kleintjes voor het eerst uit hun nest, waar ze overigens wel iedere avond opnieuw ingaan om de nacht door te brengen. Na 6 à 7 weken zijn de kuikentjes helemaal zelfstandig en kunnen ze bij de ouders weg.
            </p>
          </Grid>

          <Grid item sx={1} lg={2}></Grid>
          <Grid item sx={10} lg={8}>
            <img src={Foto4} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item sx={1} lg={2}></Grid>

          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Broedproblemen
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Het broeden loopt niet altijd zoals gewenst als hierboven geschreven. Er kunnen zich heel wat problemen tijdens het broeden voordoen:
            </p>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <MKTypography>Het popje legt geen eieren</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                Dit kan komen doordat je popje te dik is of niet de juiste voeding heeft gekregen. Of je parkietje is te jong, of ze heeft geen nestgelegenheid.
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <MKTypography>Het popje legt onbevruchte eitjes</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                Er is geen paring geweest, of door verkeerde zitstokken, of de parkietjes zijn teveel bevederd, het mannetje heeft te lange nagels, je parkietjes zijn niet in goede conditie, er is niet voldoende licht.
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                <MKTypography>Eitjes komen niet uit</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  Dit kan komen door een te grote droogte in het broedblok. Zorg dat de luchtvochtigheid goed is. De aanbevolen luchtvochtigheid is 55/65 procent. Een andere mogelijkheid is dat het embryo is afgestorven.
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
              <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                <MKTypography>Eitjes worden stuk gebeten</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  Dit komt helaas ook voor en deze parkietjes noemen we "eier-eters". Je kan na het leggen van de eitjes de eitjes weghalen je popje een vals ei geven waar ze dan in pikt. Als ze merkt dat ze dit niet stuk kan krijgen dan stopt ze ermee. Ze kan opnieuw met haar eigen eitjes beginnen te broeden. Vaak helpt dit. Als ze dit echter na dit blijft doen kun je het popje jammer genoeg niet meer laten broeden.
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
              <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                <MKTypography>De kleintjes worden niet gevoed</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  De kuikentjes moeten binnen de 12 uur gevoed worden door de pop, anders sterven ze. Het kan zijn dat het popje, als ze voor een eerste keer broedt, geen kropmelk heeft waardoor ze de kleintjes niet kan voeden. Het beste kun je dan de kleintjes onderbrengen bij een ander broedend koppeltje.
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
              <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                <MKTypography>De kleintjes worden gedood door een van de ouders</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  Dit kan komen door een popje dat te zenuwachtig is. Bij het mooi maken van haar blok, of door veranderingen kan ze dan de kleintjes doden. Soms doen popjes dit ook bij iets oudere kuikens, omdat ze opnieuw wil gaan broeden. Ook de man doodt soms de jongen!
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
              <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                <MKTypography>Kuikens groeien slecht of sterven</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                  Kleintjes krijgen in het begin kropmelk dat heel eiwitrijk is, daarom is het ook heel belangrijk dat het popje extra eiwitten krijgt. Als er te weinig eiwitten in de kropmelk zitten groeien de kuikens niet goed. Ook kropontsteking kan een doodsoorzaak zijn. Een van de ouders draagt dan een bacterie bij zich die overgebracht wordt op de kuikens. Indien de weerstand van de jongen verzwakt is, kunnen ze sterven.
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
              <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                <MKTypography>Spreidpootjes</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                <a href="http://www.parkietenfreak.nl/z-spreidpoten.php" target="_blank">Spreidpootjes</a> kunnen komen door een vitamine gebrek of door een popje dat te zwaar op de jongen ligt en dus eigenlijk het kleintje heeft platgedrukt. In de meeste omstandigheden kun je de spreidpootjes genezen door te zorgen voor voldoende vitaminen en door aan ieder pootje een ringetje te doen en deze te verbinden met een elastiekje zodat de pootjes niet meer uit elkaar kunnen. Dit moet dan een zevental dagen blijven zitten.
                </MKTypography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
              <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
                <MKTypography>Legnood</MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography>
                Legnood wil zeggen dat je parkietje haar eitje niet kan leggen. Dit kan komen door: - of eieren zonder kalkschaal (windei)
                <ul style={{marginLeft: "1em"}}>
                  <li>of te vochtige, tochtige behuizing</li>
                  <li>of het parkietje is te dik of te jong of te zwak</li>
                  <li>of door een plotselinge weersverandering</li>
                  <li>of door vitamine gebrek (verkeerde voeding)</li>
                </ul>
                Een grasparkietje dat legnood heeft herken je aan het "opgeblazen en stilletjes" in een hoekje van de kooi op de bodem of op haar nestje zit. Met opgezette veertjes en met een hevige, hijgende ademhaling ziet zij er ziek en zielig uit. Ze zal weinig of helemaal niet meer eten.
                </MKTypography>
              </AccordionDetails>
            </Accordion>            
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Het ringen van de kuikens
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Als de kuikens zeven dagen oud zijn kan een metalen nummer ring rond het pootje aangebracht worden. Kleurringen van plastic kunnen ook later aangebracht worden. De ring moet dan over het pootje worden geschoven. Het beste laat je dit doen door een ervaren kweker die je dan ook meteen kan tonen en uitleggen hoe het moet zonder teveel stress te veroorzaken voor de kleintjes. De ringen moeten de eerste dagen gecontroleerd worden, omdat de moedervogel (dit voor haar vreemde voorwerp) met de nodige handigheid zal proberen het te verwijderen. Ook is de controle nodig om te voorkomen dat er uitwerpselen tussen de ring en het pootje zouden terechtkomen. De ring zou dan aan het pootje kunnen vastkleven met als gevolg dat het ontstekingen kan veroorzaken. Het ringen van grasparkieten is niet verplicht maar wel handig. Je kunt aflezen van welk geboortejaar ze zijn, wie de ouders zijn, wie de kweker is, enz enz. Hobbykwekers ringen hun parkieten om inteelt te vermijden. De ringen zijn te koop bij vogelverenigingen.
            </p>
          </Grid>
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Broeden;
