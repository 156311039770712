// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logo from "assets/images/Logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Parkieten Oosterhout",
    image: logo,
    route: "/",
  },
  socials: [
    
  ],
  menus: [
    {
      name: "Algemeen",
      items: [
        { name: "Homepagina", route: "/"},
        { name: "Gastenboek", route: "/gastenboek" },
        { name: "Contact", route: "/contact" },
        { name: "Landkaart", route: "/landkaart" },
        { name: "Onze Vrienden", route: "/vrienden" },
      ]
    },
    {
      name: "Parkieten",
      items: [
        { name: "Grasparkiet", route: "/grasparkiet" },
        { name: "Engelse Grasparkiet", route: "/Engelse_Grasparkiet" },
        { name: "Catharina", route: "/catharina" },
        { name: "Pruimekopparkiet", route: "/pruimekopparkiet" },
        { name: "Valkparkiet", route: "/valkparkiet" },
        { name: "Kakariki", route: "/kakariki" },
        { name: "Swiften", route: "/swiften" },
        { name: "Bourkes", route: "/bourkes" },
      ],
    },
    {
      name: "Overige informatie",
      items: [
        { name: "Broeden", route: "/broeden" },
        { name: "Tam maken", route: "/tam_maken" },
        { name: "Voeding", route: "/voeding" },
        { name: "Vitaminen", route: "/vitaminen" },
        { name: "Recepten", route: "/recepten" },
      ],
    },
    {
      name: "Extra's",
      items: [
        { name: "Speeltjes", route: "/speeltjes" },
        { name: "Gedrag", route: "/gedrag" },
        { name: "Namen", route: "/namen" },
        { name: "Kleurslagen", route: "/kleurslagen" },
        { name: "Recepten", route: "/recepten" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} {" "}
      <MKTypography
        component="a"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        RheWa.Design
      </MKTypography>
      .
    </MKTypography>
  ),
};
