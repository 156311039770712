/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Foto1 from "assets/images/Op soort/Swiften/Vogels-1.jpg"
import Foto2 from "assets/images/vogels/Vogels-140.jpg"
import Foto3 from "assets/images/vogels/Vogels-137.jpg"
import Foto4 from "assets/images/Zelf/Zelf-115.jpg"
import Foto5 from "assets/images/vogels/Vogels-136.jpg"

function Swiften() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
            Swiften
            </MKTypography> 
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Algemeen
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            De swiftparkiet kreeg zijn Latijnse benaming, Lathamus Discolor, van de Britse ornitholoog Dr. John Latham (1740-1837). De soortnaam ‘discolor’ duidt op het bont gekleurde verenpak van de swiftparkiet. De swiftparkiet is de enige vertegenwoordiger van het geslacht lathamus en verschilt in vele opzichten van alle andere parkieten. Hij vertoont namelijk kenmerken van zowel parkieten als van lori’s. Eigenlijk is de swiftparkiet een tussenvorm tussen de parkieten en de lori’s. In hun bewegingen lijken ze veel op lori’s, die ook veel klauteren en maar weinig op de grond komen. Verder verschilt hun tong ook duidelijk van die van parkieten, deze is min of meer gevormd voor het opnemen van nectar.
            </p>
            <p style={{marginBottom:"1em"}}>
            In 1863 werden de eerste swiftparkieten in Europa ingevoerd. Ze werden gehuisvest in de dierentuin van Londen. Rond 1880 werd de swiftparkiet in grote aantallen in Europa ingevoerd. Veel van deze vogels kwamen toen terecht in Duitsland. De eerste broedresultaten in gevangenschap werden behaald door een Fransman in de periode 1882 tot 1887.
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto1} alt="" class="imgright" width="100%" />
          </Grid>

          <Grid item xs={12} lg={4}>
            <img src={Foto2} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Uiterlijk & geslachtsonderscheid
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            De Swiftparkiet is ongeveer 25 cm groot. Op de bovenzijde is hij groen. Het voorhoofd, keel, voorste wangen, vleugelboog, klein vleugeldek en ondervleugeldek zijn rood. De bovenkop donkerblauw. De teugel evenals de randen van de rode gezichtstekening zijn geel van kleur. Het onderdek gaat van turkooisblauw over naar groen. Het borst en buikgevederte is meer geelgroen. Het onderstaartdek is rood geschubd met wat groen. Het arm en handvleugeldek is lichtblauw, evenals de slagpennen, die gele zomen bezittende middelste staartveren zijn roodbruin en bezitten donkerblauwe punten. De buitenste staartveren zijn donkerblauw met roestkleurige zomen. Het staartonderdek is grijs. De snavel is hoornkleurig en de poten bruin.
            </p>
            <p style={{marginBottom:"1em"}}>
            Hoewel op het eerste gezicht lastig, is bij een nadere inspectie van de vogels, het geslacht van deze vogels redelijk te bepalen. De mannen zijn wat intensiever van kleur. Het rood rond de snavel is feller en de man heeft meer blauw op de kop. De rode stuit veren van de pop zijn veel minder rood dan die bij de mannen. Verder is de iris bij de man meer oranjegeel en is die van de pop bruingeel. De jongen zijn veel matter van kleur dan hun ouders en hebben ook nog geen gekleurde irisring. Ook is het mogelijk, afhankelijk van de roodintensiteit van de poppen, om het onderscheid waar te nemen in een tweetal rugdekveren. Bij de jonge poppen zijn deze soms geel/rood en bij de mannen zijn ze volledig rood. Goede poppen hebben als ze volwassen zijn ook rode rugdekveren. De onderste stuitveren zijn bij de man intensief rood en bij de pop veel minder intensief rood. De jongen ruien op een leeftijd van 5 maanden en zijn op een leeftijd van 9 maanden volledig op kleur. Ook komt na het ruien de irisring van de man op kleur (oranje). Naar gelang de vogels ouder worden, worden ze steeds mooier van kleur.
            </p>
          </Grid>

          <Grid item xs={12}>
            <p style={{marginBottom:"1em"}}>
            Als de jongen in de veren zitten kan de aanwezigheid van een vleugelstreep aan de binnenzijde van de vleugels soms iets zeggen over het geslacht. Een vleugelstip (spot) genoemd, maar niet altijd goed zichtbaar, duidt vaak op een man. Na de 1e of 2e rui verdwijnen deze spots vaak. Een duidelijke brede vleugelstreep over de gehele veer duidt op een pop. Ervaring met een kweekkoppel om dit te kunnen vaststellen is een vereiste omdat dit per kweekkoppel kan verschillen.
            </p>
          </Grid>

          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Huisvesting
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Swiftparkieten zijn in het algemeen geen moeilijke vogels om te houden. Het best gebruikt men hiervoor een gecombineerde binnen en buitenvolière. De ze mag uit hout gemaakt zijn en met groene planten beplant worden, in het bijzonder kleine bosjes of boompjes, die ze gemakkelijk kunnen gebruiken om in te klauteren. Let er echter wel op dat het groen hen niet hindert om over en weer te kunnen vliegen, ook omdat ze zich gemakkelijk kwetsen bij het uitvoeren van hun snelle vlucht.
            </p>
            <p style={{marginBottom:"1em"}}>
            Omdat swiftparkieten niet agressief zijn kunnen ze prima in een gemengde volière met andere tropen en vredelievende parkieten gehouden worden. Buiten de broedtijd zitten mijn 2 koppels swiften in een gememengse voliere met verschillende neophema's, grasparkieten en bergparkieten. In de broedtijd is de voliere wat minder druk bezet. Omdat swiften zeer goede en snelle vliegers zijn, willen ze graag wat ruimte hebben. Ik houd ze zelf in een voliere van 4 tot 5 meter en vind dit perfect. Er zijn ook mesne die zeggen dat swiften in verband met hun snelle vlucht beter in een iets kleinere voliere kunnen zitten zodat ze niet zulke grote snelheden bereiken en zich niet kunnen verwonden. Ik heb zelf echter nog nooit problemen gehad.
            </p>
            <p style={{marginBottom:"1em"}}>
            Swiftparkieten zijn echte acrobaten en mogen graag klimmen. Een klimboom is dan ook onontbeerlijk in de volière. Indien mogelijk geef ik mijn swiften dagelijks verse jonge takken. Ze klauteren dan de hele dag in het rond en doen zich te goed aan de jonge schors.
            </p>
            <p style={{marginBottom:"1em"}}>
            Uiteraard moeten ze wel beschikken over een beschut, tocht-, vocht- en vorstvrij binnenverblijf. 's Zomers mogen mijn swiften 's nachts gewoon buiten blijven. Ik zorg dan wel voor voldoende nacht verlichting in de tuin zodat ze nog wel iets kunnen zien als ze schrikken. 's Winters en in de herfst haal ik ze 's nachts naar binnen. Omdat ze graag aan het gaas hangen bestaat groot gevaar voor bevroren poten als het echt koud wordt. Alleen als het heel hard vriest verwarm ik bij zodat het vorstvrij blijft in de schuur waar ik mijn nachtverblijf heb gebouwd.
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto3} alt="" class="imgright" width="100%" />
          </Grid>

          <Grid item xs={12} lg={4}>
            <img src={Foto4} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Gedrag
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            De Swiftparkiet is een zeer actieve vogel die veel leven in onze volières brengt. Uitgezonderd de uren rond de middag vliegt, klautert en klimt de swiftparkiet de hele dag, vaak fanatiek fluitend, door de volière. Indien voorradig geef ik ze dagelijks een grote bos verse wilgen of fruitboom takken. Ze schillen deze takken kaal en klauteren er in, wat goed is voor hun poten en snavel. Ook een bak water dient elke dag tot hun beschikking te staan omdat ze erg graag badderen. De swiftparkiet is een zeer verdraagzame vogel en kan gerust in een gemengde volière worden gehouden. Wanneer ze hun verzorger leren kennen worden ze erg tam, soms zelfs een beetje brutaal. Bij het voeren komen ze soms gewoon op je hand zitten en beginnen alvast aan hun maaltijd
            </p>
            <MKTypography variant="h2" mb={"1em"}>
            Kweek
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Er kan met swiften ook in kolonie verband gekweekt worden. Het is in dat geval verstandig om met een oneven aantal vogels te kweken met het aantal vrouwtjes in de meerderheid. Een swift man kan eenvoudig nestelen met 2 verschillende poppen.
            </p>
            <p style={{marginBottom:"1em"}}>
            Als de broedtijd is aangebroken selecteert de pop een geschikte nestgelegenheid. In een volière is het dan ook noodzakelijk meerdere nestkasten op te hangen zodat ze een keuze mogelijkheid heeft. Door knaaggedrag aan het hout in de nestkast is het zichtbaar dat een pop in broedstemming is en dat er spoedig eieren te verwachten zijn. In de volière of in een broedkooi, broeden de swiftparkieten in een nestblok van ongeveer 40 cm hoog en een bodem ongeveer oppervlak van 20 x 20 cm. Het in vlieggat heeft een diameter van 5,5 tot 6,0 cm. Het nest blok kan onder hoek van 45°, maar ook recht opgehangen worden (in dat geval met aan de binnenkant een trapje). De bodem moet komvormig zijn en als bodembedekking vochtig een mengsel van vochtige turf en houtsnippers. Een pop legt om de dag, 3 tot 5 eieren die 19 dagen bebroed worden. De jongen moeten tussen de 7de en 8ste dag worden geringd met ringen van 5,0 mm. In de broedperiode en de eerste weken dat er jongen zijn, is ze voor wat betreft de voeding afhankelijk van de man. De pop wordt 's morgens en 's avonds door de man gevoed. Op dit tijdstip verlaat de pop ook vaak even het nest om zich te ontdoen van haar ontlasting. Na een week of vijf komen de jongen voor het eerst naar buiten. De pop blijft tot dat moment ook meestal 's nachts bij de jongen. Bij de jongen ontstaat na een aantal weken een witte vlek op het achterhoofd. Als de swiften jongen hebben is het beslist noodzakelijk regelmatig het nestmateriaal te verschonen omdat hun natte ontlasting vooral in de eerste weken zorgt voor een veel te vochtig nest. In tegenstelling tot andere vogels is het verschonen van het nestmateriaal geen enkel probleem. Bij warm weer moet men ook zorgen voor voldoende ventilatie. Het mag namelijk niet te warm worden in het nestblok.
            </p>
          </Grid>
          
          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Voeding
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            In zijn thuisland voedt de swiftparkiet zich hoofdzakelijk met nectar en pollen, doch eveneens verschillende insecten zorgen voor de nodige afwisseling. Verder talrijke vruchten, zaden, knoppen, bladeren en bessen. Ze zoeken zelden de bodem op al nemen ze ook wel eens graszaden tot zich. Het grootste deel van hun voedsel vinden ze in de bomen. Dit gevarieerde natuurlijke dieet maakt het noodzakelijk ook het voedingspatroon in onze volières hierop aan te passen. Uiteraard moet er altijd schoon water, grit, sepia en maagkiezel tot hun beschikking staan.
            </p>
          </Grid>
          
          <Grid item sx={1} lg={2}></Grid>
          <Grid item sx={10} lg={8}>
            <img src={Foto5} alt="" class="imgright" width="750%" />
          </Grid>
          <Grid item sx={1} lg={2}></Grid>
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Swiften;
