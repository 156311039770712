/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import * as React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Foto1 from "assets/images/Op soort/Jonge vogels/Vogels-23.jpg"
import Foto2 from "assets/images/vogels/Vogels-66.jpg"

import PhotoAlbum from "react-photo-album";
import ImageViewer from 'react-simple-image-viewer';
import TamMakenPhotos from "./TamMakenPhotos";

function TamMaken() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [index, setIndex] = React.useState(-1);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
    console.log(TamMakenPhotos.map(item => item.src))
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="35vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
              Tam maken
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            De eerste dagen
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Je parkiet is voor het eerst in zijn nieuwe huis, natuurlijk is dit allemaal wat vreemd en de parkiet heeft daarom ook een hoop nieuwe indrukken te verwerken.
            </p>
            <p style={{marginBottom:"1em"}}>
            Laat je parkiet de eerste dag nog wat met rust, hij moet even de kans krijgen om te wennen. Blijf wel in de buurt en praat tegen de parkiet om hem op zijn gemak te stellen. Sommige zijn nog bang en blijven eerst stil in een hoekje zitten of op een stokje ook eten ze vaak de eerste dag niet veel, dat is natuurlijk niet raar want het is een hele beleving voor je parkiet
            </p>
            <p style={{marginBottom:"1em"}}>
            Vanaf dag 2 kan je de parkiet tam gaan maken. De parkiet heeft alles op zijn gemak kunnen bekijken en tot rust kunnen komen van zijn avonturen van de vorige dag.
            </p>
            <p style={{marginBottom:"1em"}}>
            Je kan nu proberen met je hand in de kooi te gaan, dit kun je eventueel doen met een stukje trosgierst in je hand want dat vinden ze lekker, praat ondertussen met je parkiet om hem gerust te stellen Het kan zijn dat hij de eerste keer gaat fladderen met z'n vleugels omdat hij bang is, belangrijk is om je hand niet terug te trekken want anders doet hij het de volgende keer dat je hand in de kooi komt weer.
            </p>
            <p style={{marginBottom:"1em"}}>
            Vanaf dag 2 kan je de parkiet tam gaan maken. De parkiet heeft alles op zijn gemak kunnen bekijken en tot rust kunnen komen van zijn avonturen van de vorige dag.
            </p>
            <p style={{marginBottom:"1em"}}>
            Je kan nu proberen met je hand in de kooi te gaan, dit kun je eventueel doen met een stukje trosgierst in je hand want dat vinden ze lekker, praat ondertussen met je parkiet om hem gerust te stellen Het kan zijn dat hij de eerste keer gaat fladderen met z'n vleugels omdat hij bang is, belangrijk is om je hand niet terug te trekken want anders doet hij het de volgende keer dat je hand in de kooi komt weer.
            </p>
            <p style={{marginBottom:"1em"}}>
            Herhaal dit een paar keer per dag, het zal niet meteen lukken, want de parkiet moet eerst vertrouwen in je krijgen. Na een poosje de kat uit de boom te hebben gekeken zal de parkiet een paar zaadjes van de trosgierst eten, dan is zijn angst een eind overwonnen. Blijf de trosgierst aanbieden, op den duur zal hij op je vinger gaan zitten. Bij de ene parkiet gaat het sneller dan bij de ander, misschien komt jou parkiet al veel eerder bij je zitten.
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto1} alt="" class="imgright" width="100%" />
          </Grid>

          <Grid item xs={12} lg={4}>
            <img src={Foto2} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Uit de kooi
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Is je parkiet voldoende tam en komt hij gemakkelijk op je hand/vinger, dan is het fijn voor de parkiet als hij lekker een poosje buiten zijn kooi mag vliegen. Let voor je hem vrij laat op een aantal dingen zoals: Vitrage dicht zodat hij niet tegen de ramen vliegt Vazen of andere hoge voorwerpen met water er in weg zetten, daar kan hij in verdrinken. Zorg dat iedereen weet dat je parkiet los is zodat er niet plotseling een deur open gaat. Blaas de kaarsen uit, want anders kan je parkiet zich verbranden. Zorg dat er geen giftige planten meer aanwezig zijn in de kamer.
            </p>
            <p style={{marginBottom:"1em"}}>
            Als je alles gereed heb gemaakt voor de eerste vlucht. kan je de parkiet uit de kooi laten. Je kan het deurtje van de kooi openen zodat de parkiet zelf kan beslissen wanneer hij er klaar voor is, of je kan hem op je vinger uit de kooi halen. Als hij er voor het eerst uit gaat zal hij waarschijnlijk eerst een veilige plaats uitzoeken om te landen.Zo kan hij alles nog eens goed bekijken. Praat wat tegen hem, zodat hij gerustgesteld is.
            </p>
          </Grid>

          <Grid item xs={12} style={{background:"#141a1a", marginLeft:"1em", padding:"1em", border: "2px solid #141a1a", borderRadius: "1.5em"}}>
            <PhotoAlbum photos={TamMakenPhotos} layout="masonry" onClick={({ index }) => openImageViewer(index)}/>
            {/* <MasonryPhotoAlbum photos={TamMakenPhotos} targetRowHeight={150} onClick={({ index }) => setIndex(index)} /> */}

            {isViewerOpen && (
              <ImageViewer
                src={ TamMakenPhotos.map(item => item.src) }
                currentIndex={ currentImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                backgroundStyle={{zIndex:"12"}}
              />
            )}
          </Grid>
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default TamMaken;
