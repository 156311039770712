/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Foto1 from "assets/images/vogels/Vogels-119.jpg"
import Foto2 from "assets/images/Op soort/Catharina/Vogels-2.jpg"
import Foto3 from "assets/images/Op soort/Catharina/Vogels-3.jpg"
import Foto4 from "assets/images/vogels/Vogels-144.jpg"

function Catharina() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
                mt={"2em"}
              >
              De Catharina parkiet
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
              Geschiedenis
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
              De herkomst van deze parkiet is vanaf het zuiden van Mexico tot West Panama en vervolgens van het Noord-Westen van Venezuela tot in Centraal Peru. Zij leven afhankelijk van hun leefgebied en het jaargetijde tussen de 400 en 3000 meter boven de zeespiegel. Hun leefgebied varieert van boomrijke savannen tot dichte berg(nevel)wouden. Het zijn echte klimmers, die langs de takken omhoog en omlaag klauteren.
              Dit gedrag ziet men ook terug aan hun houding in de tentoon-stellingskooi. Rustig en niet opvliegerig, waarbij ze vaak langzaam, iets in elkaar gedoken, over de stok klauteren en lopen. Gesteld mag worden, dat deze soort volledig is gedomesticeerd en dat er veelvuldig broedresultaten voorkomen in de volieres, terwijl een vrij groot aantal mutanten is ontstaan. Naast de wildvorm, Bolborhynchus I. lineola, waarvan de kleur als groen is omschreven, kennen we als ondersoort de Bolborhynchus I. tigrinus, de Peru-Catharinaparkiet. Deze laatste wordt in de literatuur omschreven als donkerder van kleur, met wat bredere zwarte zomen aan de veren.en een meer uitgebreide schoudervlek.
              Sommigen noemen hem ook iets kleiner als de nominaatvorm. Vast staat dat de verschillen tussen beide soorten minimaal zijn en voor de gewone liefhebber/keurmeester nauwelijks uit elkaar te houden zijn. Het is niet exact bekend of de ondersoort tigrinus als aparte soort is ingevoerd en als zodanig is herkend. Aangenomen mag worden dat, indien ze ingevoerd zijn, ze gewoon met de nominaatvorm zijn gepaard. Derhalve zijn de verschillen tussen de soorten nog verder vervaagd.
              Door deze kweekselectie is een echte cultuurvogel ontstaan, de in deze standaard beschreven Catharinaparkiet is dan ook te beschouwen als de Bolborhynhus I. domestica.
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto1} alt="" class="imgright" width="100%" />
          </Grid>

          <Grid item xs={12} lg={4}>
            <img src={Foto2} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
              Beschrijving
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
              De catharina parkiet (barred parakeet/bolborhynchus lineola) treft men oorspronkelijk aan in het tot 2500 meter hoge gebergte van Zuid Mexico tot West Panama. Daar wordt de wildvorm van deze vogels aangetroffen, die een grootte van 19 centimeter kan bereiken. Opvallend is het smaragdgroene vederkleed, waarbij de kop, hals en het midden van de onderzijde meer geelbruin is. De boven- en onderlichaam zijden zijn fijn zwart afgeboord. De snavel is hoornkleurig. De poten zijn vleeskleurig grijs en het oog is geelachtig bruin. Bij jonge vogels zijn poten, snavel en gevederte helder, t.t.z. lichter. De catharinaparkiet zoekt in zijn wilde habitat ijverig naar zaden van grassen, kruiden, bessen en zelfs insecten. Men treft ze gewoonlijk aan in groepen van een dertigtal vogels, hoog in de bomen. Ze kunnen grote afstanden vliegen zonder gezien te worden vanaf de grond. Deze vogels zijn over het algemeen wanneer ze voldoende geacclimatiseerd zijn, zeer aanhankelijk en zeer rustig. Het dient aan te bevelen bij eventuele aankoop vogels te nemen welke hier geboren zijn. De catharinaparkiet kan hier zonder problemen overwinteren mits men over een tochtvrije ruimte beschikt. Vooral s'morgens en s'avonds zijn deze vogels heel actief en ziet men ze ondersteboven aan de draad van de kooi of volière hangen.
            </p>
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
              Geslachtskenmerken
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
              Het opvallendste verschil tussen de sexen is dat over het algemeen bij het mannetje de staartuiteinden gans zwartgekleurd zijn. Bij de poppen is dit slechts een heel klein stukje zwart of ontbreekt het zwart zelfs. Ook de zwarte vlekken op de vleugels en op de stuit zijn groter en meer geaccentueerd bij de mannetjes. Over het algemeen genomen is de snavel ook wat zwaarder bij de man en is hij ook wat groter dan de poppen. Bij de lutino en albino mutaties is men verplicht de vogels te laten sexen door een dierenarts.
            </p>
          </Grid>
          
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
              Kweek
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
              De catharinaparkiet is ongeveer na een zestal maand reeds broedrijp. Dit geld vooral voor de man. Voor de pop mag men op een leeftijd van een achttal maanden rekenen. Het is echter aan te bevelen pas na een jaar met deze vogels te beginnen kweken, daar ze dan de gelegenheid hebben om tot volle ontwikkeling te komen. Bij mutatiekweek is men aangewezen op de kweek in ruime kweekkooien. Als nestkastje gebruikt men bij voorkeur een grasparketenkastje welke men opvult met houtkrullen. Catharinaparkieten zijn zeer broedlustig. De pop legt een vijf tot zestal eieren om de dag welke voornamelijk door haar uitgebroed worden in een periode van 23 dagen. De man neemt de voeding van de pop voor zijn rekening en helpt slecht sporadisch bij het broeden. Men kan steeds zonder problemen nestcontrole uitvoeren. De kleintjes groeien vlug op en verlaten reeds na een zestal weken het nest. Na nog een paar weken zijn deze zelfstandig genoeg om de ouders te verlaten.
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto3} alt="" class="imgright" width="100%" />
          </Grid>

          <Grid item xs={12} lg={4}>
            <img src={Foto4} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
              Huisvesting
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            De Catharinaparkieten en hun soortgenoten leven in het wild op soms vrij grote hoogte in bergachtige gebieden in Zuid-Amerika waar het fris kan zijn. De catharina parkiet kan in west europa best buiten gehouden worden, zeker in de zomer. Ook in het koudere seizoen kunnen ze wel naar buiten, maar zorg ervoor dat vooral de grond in de buitenvolière droog is. Ze voelen zich niet prettig in een al te natte omgeving of bij vochtig koud weer, zoals dat in de herfst nogal eens voorkomt. Een buitenvolière moet dus minstens voor een deel overdekt zijn en er moet een goed droog nachthok aan vast zitten. In de winterperiode moeten we er op letten dat de vogels in de binnenvlucht water krijgen anders bevriest dit bij vorst. Het kweken met Catharinaparkieten in een buitenvolière gaat goed, mits er genoeg ruimte is kunnen er meerdere paren gehouden worden. Let er wel op dat tijdens de broedperiode er genoeg broedblokken in de volière hangen. Het nadeel van deze manier van broeden is dat de vogels zich zelf gaan koppelen aan een andere catharina en je ze niet kunt dwingen om bij een bepaalde kleur te gaan.
            </p>
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
              Gezondheid
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
              Hoewel Catharina's evenals andere vogels ziek kunnen worden zijn ze, voor zover bekend, niet uitzonderlijk vatbaar voor bepaalde ziektes. Goede huisvesting is noodzakelijk voor het gezond blijven van de vogels, overmatige luchtvochtigheid en smerige zitstokken en bodems is natuurlijk vragen om ellende. Het voer moet afwisselend en niet te vet zijn, wanneer een Catharina zijn gang mag gaan eet hij het liefst enkel zonnepitten en wordt hij snel te vet. De nagels dient u goed in de gaten te houden, deze groeien extreem snel bij de catharina's. Wanneer de nagels te lang zijn moeten deze worden ingekort om te voorkomen dat de vogel ergens aan blijft hangen of in het broedeizoen de eitjes en jongen beschadigd. Let er bij het knippen op dat u geen bloedvaten raakt, hou een zakmes en een aansteker bij de hand zodat u met de gloeiende punt de nagel kunt dichtbranden mocht het toch fout gaan.
            </p>
          </Grid>
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Catharina;
