/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Foto1 from "assets/images/Op soort/Parkieten/Vogels-3.jpg"
import Foto2 from "assets/images/Zelf/Zelf-131.jpg"
import Foto3 from "assets/images/vogels/Vogels-161.jpg"
import Foto4 from "assets/images/Op soort/Parkieten/Vogels-6.jpg"

function Pruimekopparkiet() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
              De Pruimekopparkiet
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
              Geslachtsonderscheid
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Bij volwassen vogels is het verschil tussen beide geslachten gemakkelijk te zien. De kop is bij de mannen donkerroze met een zwarte omzoming, terwijl de kop van de vrouwtjes meer blauwgrijs van kleur is met een geelachtige omzoming. Bovendien hebben de volwassen mannetjes een rode schoudervlek.
            </p>
            <MKTypography variant="h2" mb={"1em"}>
              Sociale eigenschappen
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Buiten de broedperiode zijn de dieren verdraagzaam ten opzichte van soortgenoten en kunt u ze in kleine groepjes houden. Daarbuiten houdt u ze bij voorkeur in koppels. Geschikte behuizing Pruimkop parkieten kunnen in een buitenvoliére, maar ook in een ruime kooi of kamervoliére gehouden worden.
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto1} alt="" class="imgright" width="80%" />
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
              Omgevingstemperatuur
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
              Pruimkop parkieten zijn redelijk gehard, maar moeten over een vorst-, tocht-, en vochtvrij nachthok kunnen beschikken. Soms zal een verwarmd nachthok noodzakelijk zijn. Voedsel Geef deze vogels als basisvoer een zaadmengsel voor grote parkieten. U kunt dit aanvullen met allerhande groenvoer en fruit ( niet te veel ), gekiemde zaden en trosgierst. Ook dierlijke eiwitten worden van tijd tot tijd graag opgenomen. Denk hierbij aan onder meer eivoer. Uiteraard horen de vogels altijd te kunnen beschikken over een gritmengsel. Doorgaans nemen deze vogels geen voedsel van de grond. Ze knagen graag op verse dunne wilgentakjes maar zijn geen notoire slopers. Ze zijn vocaal ingesteld en hun stem geluid- voornamelijk dat van het mannetje- kan heel melodieus zijn.
            </p>
          </Grid>

          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
              Kweek
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Wanneer u met deze vogels wilt kweken, kunt u het beste een kweekkoppel naar een aparte vlucht of ruime kweekkooi overbrengen. Tijdens de kweekperiode kunnen pruimkop parkieten paren die normaliter harmonisch samenleven, agressief tegen elkaar worden. Oplettendheid is dan ook geboden. De vogels accepteren zowel een fabrieksmatig broedhok als een uitgeholde natuurstam als broedplaats. De bodem moet een doorsnede hebben van minimaal 25 centimeter en de hoogte van het broedblok moet ongeveer 40 tot 50 centimeter zijn. Een invlieggat van gemiddeld 7 centimeter is toereikend. Hang het broedblok bij voorkeur in het nachthok op. Er wordt geen nest gebouwd. Als ondergrond worden stukjes vermolmd hout of wat vochtige turfmolm geaccepteerd.
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto2} alt="" class="imgright" width="100%" />
          </Grid>

          <Grid item xs={12} lg={4}>
            <img src={Foto3} alt="" class="imgright" width="80%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
              Eitjes
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Pruimkop parkieten leggen 4 tot 5 witte eitjes. Het vrouwtje broed alleen, en na ongeveer 22 tot 24 dagen komen de jongen uit het ei. Op een leeftijd van ongeveer zes weken vliegen de jongen uit. Ze worden dan nog een poos hoofdzakelijk door het mannetjes gevoerd en begeleid. De jongen krijgt hun volwassen kleur pas als ze ongeveer anderhalf tot tweejaar oud zijn en soms nog later. Voor die tijd lijken alle dieren qua kleur op vrouwtjes. Pas als ze drie jaar oud zijn, zijn ze echt volwassen en geheel op kleur. Voor die tijd kunt u ze beter nog niet voor de kweek inzetten. Het is erg belangrijk dat u de vogels tijdens de kweekperiode zo veel mogelijk met rust laat. Wanneer de dieren storing ervaren, kunnen ze als reactie hierop hun eitjes of jongen in de steek laten.
            </p>
          </Grid>

          <Grid item sx={1} lg={2}></Grid>
          <Grid item sx={10} lg={8}>
            <img src={Foto4} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item sx={1} lg={2}></Grid>
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Pruimekopparkiet;
