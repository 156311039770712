/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Foto1 from "assets/images/Op soort/Bourkes/Vogels-25.jpg"
import Foto2 from "assets/images/Op soort/Bourkes/Vogels-26.jpg"
import Foto3 from "assets/images/Op soort/Bourkes/Vogels-21.jpg"
import Foto4 from "assets/images/Op soort/Bourkes/Vogels-4.jpg"
import Foto5 from "assets/images/Op soort/Bourkes/Vogels-1.jpg"

function Bourkes() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
              Bourkes
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Algemeen
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Van de Bourkes parkiet zijn geen ondersoorten bekend. Op het eerste gezicht lijken de man en de pop veel op elkaar. Maar als je ze nader goed bekijkt dan zijn er echter wel wat kleine verschillen te zien. Bij de Bourke man is het voorhoofd blauw en bij de pop is dit soms lichtblauw gekleurd. De pop is ook wat matter van kleur en dit is vooral op de buik goed te zien. De kleuren hierbij zijn tevens wat variabel van kleur. Bij de mannen ontbreekt de vleugelstreep terwijl deze bij de poppen duidelijk aanwezig is. De bovenschedel en de achterkop zijn donkerbruin. De voorhoofdsband loopt door tot boven het oog en vloeit uit naar de achterkop. De oorstreek is vuilwit. De keel en de borst zijn bruinachtig rose gerand met een regelmatige golftekening. De pop heeft wat smallere randen. De buik is roserood. De dijen en de dekveren onder de staart, de flanken en de zijkanten van de stuit zijn bleekrose. De vleugeldekveren zijn geelachtig witgerand. De dekveren onder de vleugels zijn bleekblauw. De buitenste vlaggen van de slagpennen violetblauw. De bovenstaartdekveren zijn donkerbruin. De onderstaartveren zijn lichtblauw. De bovenste grote staartdekveren zijn donkerbruin en zijn iets besproeid. De poten zijn donkerbruin met zwarte nagels en de snavel is donkergrijs. De jonge vogels lijken op de pop maar zijn minder roserood. De jonge mannen krijgen na de eerste rui na ongeveer vijf maanden al wat blauwe veertjes op het voorhoofd. Na acht tot negen maanden zijn ze volledig op kleur en na 12 maanden zijn ze broedrijp. De lengte van de Bourke parkiet is 24-25 cm en ze worden geringd met een ringmaat van 4 mm.
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto1} alt="" class="imgright" width="100%" />
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Leefomgeving
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            De Bourkeparkiet geeft de voorkeur aan droge of halfdroge terreinen die met acaciastruiken en mulga zijn begroeid. Ze voeden zich met de zaden van deze struiken, graszaden en zaden van kruiden. Het zijn vogels die in het Australische binnenland een zwervend bestaan leiden. Als de regenbuien in een bepaald gebied uitblijven verdwijnen de Bourke parkieten voor lange tijd uit dat gebied. Maar bij ideale voedselomstandigheden na voldoende regen bereiken ze een groot verspreiding. In de natuur wordt de Bourke parkiet niet zoveel waargenomen, een van de oorzaken is dat het schemervogels zijn in de vroege ochtend en tegen schemering zijn ze actief. Ook houden ze niet van bewoonde gebieden waar de mensen actief bezig zijn. Doordat de Bourke parkiet een goede schutkleur bezit zullen ze niet zo gauw opvallen. Ze komen soms zelfs 's nachts in beweging, vooral bij een heldere maan. Je kan ze dan zelfs horen vliegen. Deze activiteiten verklaren meteen waarom deze vogels zulke grote ogen hebben. Vaak komen ze in paren of kleine groepjes voor in tijden van droogte en ze bezoeken dan gezamenlijk de drinkplaatsen. In de natuur broeden ze in boomholtes tot op een hoogte van drie meter
            </p>
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Geslachtsonderscheid
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Wildkleur bourke parkieten mannetjes hebben een blauwe veertjes boven hun wasneus (op het voorhoofd), bij de pop ontbreekt de blauwe kleur. Dit verschil is pas na de eerste rui te zien. De linker bourkeparkiet op de foto is dus een man.
            </p>
          </Grid>

          <Grid item sx={1} lg={2}></Grid>
          <Grid item sx={10} lg={8}>
            <img src={Foto2} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item sx={1} lg={2}></Grid>

          <Grid item xs={12} lg={4}>
            <img src={Foto3} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Sociale eigenschappen
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Bourke parkieten zijn sociale vogels die het beste als koppel gehouden kunnen worden, meerdere koppels kunnen tot problemen leiden. Bourkes kunnen ook erg goed met andere vogels gehouden worden. In tegenstelling tot andere parkieten staan bourkes er namelijk bekend om zeer verdraagzaam te zijn tegenover, zelfs de kleinste, tropische vogels.
            </p>
            <MKTypography variant="h2" mb={"1em"}>
            Huisvesting
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Bourke parkieten zijn geschikte volièrevogels, maar voor een kooi zijn ze minder geschikt, het zijn namelijk echte vliegers. Bourkes zijn winterharde vogels, maar een tocht- en vorstvrij nachthok waarin zij zich kunnen terugtrekken verdient de voorkeur.
            </p>
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Voeding
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Grof parkietenvoer kan als basis gegeven worden maar er zijn gelukkig fabrikanten die een speciale Neophema-mengeling in het assortiment hebben opgenomen. Witte Molen is één van die fabrikanten. Het kost zo'n € 22.50 per 25 kilogram. Deze speciale mengeling is aan te bevelen omdat de Neophema bekend staat om de spijsverteringsproblemen. Groenvoer zoals andijvie, jonge spinazie, sla etc. kan ook goed gegeven worden. Was het wel eerst goed af met het oog op de middelen die gebruikt worden tegen insecten. Wees niet te royaal met het verstrekken van groenvoer omdat dat ongetwijfeld tot darmproblemen leidt. Verder wat trosgierst zo nu en dan als lekkernij tussendoor wordt gewaardeerd door de vogels. Grit, sepia en soortgelijke producten moeten net als vers water altijd tot de beschikking van de vogels staan.
            </p>
          </Grid>

          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Kweken
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Bourkeparkieten zijn normaal gesproken niet zo moeilijk te kweken. Eigen ervaring heeft mij geleerd dat meerdere koppels in één volière of vlucht niet de gewenste broedresultaten opleveren. De mannen zijn dan erg agressief naar mannelijke soortgenoten, om die reden kunt u beter 1 koppel per volière of vlucht laten broeden. Het broedblok dient een bodemoppervlak te hebben van 20 bij 20 cm, de hoogte kan variëren van 30 tot 35 cm. Het invlieggat dient een diameter te hebben van 6,5 cm. Dit soort blokken zijn goed zelf te maken maar zijn ook kant en klaar in de handel te verkrijgen. Bourkeparkieten maken geen nest (zoals de meeste parkieten soorten dat ook niet doen), maar leggen de eieren zo op de bodem of op een iets vochtige ondergrond. Deze kan gemaakt zijn van bijvoorbeeld zaagsel of turfmolm , maar ook de beukensnippers zijn droog te gebruiken. Als er door de eigenaar teveel van het materiaal in het blok is gelegd, zal de pop alles er uit gooien net zo lang tot ze tevreden is met het "nest". Er worden zo'n 3 tot 6 eieren gelegd (om de dag 1) welke door de pop in 18 dagen worden uitgebroed. De man voert de pop gedurende die tijd. Als de jongen zo'n 4 tot 5 weken zijn vliegen ze uit. Ze kunnen dan nog niet zelfstandig eten en worden nog twee weken door de man gevoerd. De pop begint dan vaak aan een tweede nest. Na het tweede nest kunt u de broedblokken beter weghalen met het oog op de conditie van de ouders. Een goed kweekkoppel kunt u beter niet scheidden. Ze vormen vaak een paar voor het leven. Ik heb met eigen ogen kunnen aanschouwen dat één van mijn mannen zat te verpieteren toen ik de pop bij hem had weggenomen. Goede kweekkoppels dus zo min mogelijk scheidden.
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto4} alt="" class="imgright" width="100%" />
          </Grid>
          
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Bijzonderheden
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Bourkes zijn vooral actief tijdens de schemering en zullen dan ook lekker fluiten.
            </p>
            <MKTypography variant="h2" mb={"1em"}>
            Mutaties
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Door zijn populariteit zijn er in de loop der tijd verschillende mutaties naast de wildkleur ontstaan. Zo zijn er de gele (ook wel geeldek of overgoten), de fallow (soort opgebleekte wildkleur met rode ogen), de lachs, de opaline (roze), de isabel, de lutino en de rubino. Bijzonderheden Bourkeparkieten zijn ideale vogels voor de beginnende liefhebber. Ze hebben een zacht karakter, broeden gemakkelijk (ook als pleegouders) en stellen weinig eisen aan hun verzorging. Met de nodige aandacht zullen ze zich snel gaan hechten aan de verzorger.
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto5} alt="" class="imgright" width="100%" />
          </Grid>
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Bourkes;
