/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

import React from "react";
import "leaflet/dist/leaflet.css";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Foto from "assets/images/Zelf/Zelf-74.jpg"


import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from 'leaflet';
import { CircularProgress } from "@mui/material";
import axios from "axios";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

function Landkaart() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  
  const position = [51.64389911817091, 4.856790995187352]
  const [plaatsenData, setPlaatsenData] = useState()
  const [plaatsenLoaded, setPlaatsenLoaded] = useState()

  const [aPark, setAPark] = useState(0)
  const [aPlaats, setAPlaats] = useState(0)
  const [aGras, setAGras] = useState(0)
  const [aEGras, setAEGras] = useState(0)
  const [aCat, setACat] = useState(0)
  const [aPruim, setAPruim] = useState(0)
  const [aValk, setAValk] = useState(0)
  const [aKaka, setAKaka] = useState(0)
  const [aSwift, setASwift] = useState(0)

  useEffect(() => {
    axios.defaults.withCredentials = true;
    console.log('load')
    loadPlaatsen()
  }, [])

  const loadPlaatsen = async () => {
    await axios.get('api/api.php/plaatsen')
    .then(res => {
        if (res.status === 202)
        {
            console.log("geen Data")
            setPlaatsenData([])
        }
        else if (res.status === 200)
        {
          setPlaatsenData(res.data)

          setAPlaats(res.data.length)
          let gras = 0
          let egras = 0
          let kaka = 0
          let valk = 0
          let pruim = 0
          let cat = 0
          let swift = 0

          res.data.map((item) => {
            gras  += parseInt(item.Eng_gras)
            egras += parseInt(item.Gras)
            cat   += parseInt(item.Cat)
            pruim += parseInt(item.Pruim)
            valk  += parseInt(item.Valk)
            kaka  += parseInt(item.Kaka)
            swift += parseInt(item.Swift)
          })
          setAEGras(egras)
          setAGras(gras)
          setACat(cat)
          setAPruim(pruim)
          setAValk(valk)
          setAKaka(kaka)
          setASwift(swift)
          setAPark(egras + gras + cat + pruim + valk + kaka + swift)
        }
        setPlaatsenLoaded(true)
    })
  }
  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
              Landkaart
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        { plaatsenLoaded ? 
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Card>
                  <DefaultCounterCard
                    color="info"
                    count={aPark}
                    suffix=""
                    title="Parkieten Verkocht"
                    description="Totaal"
                  />
                </Card>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={3}>
                <Card>
                  <DefaultCounterCard
                    color="info"
                    count={aPlaats}
                    suffix=""
                    title="Parkieten Verkocht"
                    description="Totaal"
                  />
                </Card>
              </Grid>

              <Grid item xs={6} lg={1.7}>
                <Card>
                  <DefaultCounterCard
                    color="info"
                    count={aGras}
                    suffix=""
                    title="Grasparkiet"
                    description=""
                  />
                </Card>
              </Grid>
              <Grid item xs={6} lg={1.7}>
                <Card>
                  <DefaultCounterCard
                    color="info"
                    count={aEGras}
                    suffix=""
                    title="Engelse Grasparkiet"
                    description=""
                  />
                </Card>
              </Grid>
              <Grid item xs={6} lg={1.7}>
                <Card>
                  <DefaultCounterCard
                    color="info"
                    count={aCat}
                    suffix=""
                    title="Catharina"
                    description=""
                  />
                </Card>
              </Grid>
              <Grid item xs={6} lg={1.7}>
                <Card>
                  <DefaultCounterCard
                    color="info"
                    count={aPruim}
                    suffix=""
                    title="Pruimekop"
                    description=""
                  />
                </Card>
              </Grid>
              <Grid item xs={6} lg={1.7}>
                <Card>
                  <DefaultCounterCard
                    color="info"
                    count={aValk}
                    suffix=""
                    title="Valkenparkiet"
                    description=""
                  />
                </Card>
              </Grid>
              <Grid item xs={6} lg={1.7}>
                <Card>
                  <DefaultCounterCard
                    color="info"
                    count={aKaka}
                    suffix=""
                    title="Kakariki"
                    description=""
                  />
                </Card>
              </Grid>
              <Grid item xs={6} lg={1.7}>
                <Card>
                  <DefaultCounterCard
                    color="info"
                    count={aSwift}
                    suffix=""
                    title="Swiften"
                    description=""
                  />
                </Card>
              </Grid>

              <Grid item xs={12}>
                <MapContainer 
                  center={position} 
                  zoom={10} 
                  scrollWheelZoom={true}
                  style={{ height: '70vh', width: '100wh', zIndex: '2' }}>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={position}>
                    <Popup>
                      Parkieten Oosterhout
                    </Popup>
                  </Marker>
                  {plaatsenData.map((item) => {
                    return (
                      <Marker position={[item.Lat, item.Log]}>
                        <Popup>
                          {item.Plaats}
                        </Popup>
                      </Marker>
                      )
                  })}
                </MapContainer>
              </Grid>
            </Grid>
          : <CircularProgress /> }
        
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Landkaart;
