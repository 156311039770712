/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";
import DefaultFooter from "examples/Footers/DefaultFooter";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import Foto1 from "assets/images/Zelf/Voer.jpg"
import Foto2 from "assets/images/vogels/Vogels-79.jpg"
import Foto3 from "assets/images/Zelf/Zelf-65.jpg"
import foto_v1 from "assets/images/Zelf/Zelf-42.jpg"
import foto_v2 from "assets/images/Zelf/Zelf-43.jpg"
import foto_v3 from "assets/images/Zelf/Zelf-47.jpg"
import foto_v4 from "assets/images/Zelf/Zelf-41.jpg"
import foto_v5 from "assets/images/Zelf/Zelf-44.jpg"
import foto_v6 from "assets/images/Zelf/Zelf-45.jpg"
import foto_v7 from "assets/images/Zelf/Zelf-38.jpg"
import foto_v8 from "assets/images/Zelf/Zelf-39.jpg"
import foto_v9 from "assets/images/Zelf/Zelf-40.jpg"
import foto_v10 from "assets/images/vogels/GroenteEnFruit.jpg"


function Voeding() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
              Voeding & verzorging
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <img src={Foto1} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Zaden
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Het basisvoer van de parkiet zijn zaden, In de dierenwinkel kan je verpakte zaadmengsels kopen. In dit parkietenvoer zitten de zaden die ze nodig hebben en gezond voor ze zijn. Als je maar 1 parkiet heb, kun je het beste een niet zo grote zak kopen want dan doe je er te lang mee en is het misschien niet meer goed. Let bij aankoop op de houdbaarheidsdatum, en check regelmatig die datum zodat je parkiet geen oud voer krijgt. Zorg ook altijd voor schoon vers drinkwater, ze drinken niet zo heel veel maar hebben wel water nodig. Geef het water niet te koud, want dat is niet goed.
            </p>
            <MKTypography variant="h2" mb={"1em"}>
            Vitaminen en mineralen
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Een parkiet heeft ook vitamine en mineralen nodig, net als wij. Er zijn een aantal producten die een belangrijke aanvuling zijn op het basisvoer.
            </p>
          </Grid>

          <Grid item xs={12} lg={4}>
            <img src={Foto2} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Zitstok
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Deze gezonde zitstokken worden bij uw vogel zeer geliefd. Alle voordelen in een overzicht Gemaakt van cement Zorgt voor stompe en schone verzorgdenageltjes dat wil zeggen dat u zelf de nageltjes niet hoeft te knippen. Reinigt de snavel Verschillende grote/sterkte Voorkomt artritis en atrofie (verkromming) van de pootjes Comfort en zekerheid Zet deze zitstok als bovenste stok in de kooi en je vogel gebruikt deze als slaap plek
            </p>
          </Grid>

          <Grid item xs={12} lg={4}>
            <img src={Foto3} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Zeeschuim/Sepia
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Is goed voor de opbouw van het skelet van de parkiet, Er zitten mineralen en sporenelementen in, zoals kalk en fosfor die belangrijk zijn. Het is ook erg goed voor de snavel, de parkiet gaat er aan knabbelen en houd zo z'n snavel scherp en in goede conditie.
            </p>
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Soorten voer en bodemstrooisel
            </MKTypography>
            <Grid container>
              <Grid item xs={12} lg={3.9} mb={"3em"}>
                <CenteredBlogCard
                  image={foto_v1}
                  title="Parkietenzaad"
                  description="Parkietenzaad is het basis voer voor de parkieten."
                  action={{
                    type: "none",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={0.15}></Grid>
              <Grid item xs={12} lg={3.9} mb={"3em"}>
                <CenteredBlogCard
                  image={foto_v2}
                  title="Valkenzaad"
                  description="Valkenzaad is het basis voer voor valkenparkieten."
                  action={{
                    type: "none",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={0.15}></Grid>
              <Grid item xs={12} lg={3.9} mb={"3em"}>
                <CenteredBlogCard
                  image={foto_v3}
                  title="Trosgierst"
                  description="Een favoriet van de parkiet is de trosgierst, dit vinden ze echt een lekkere snack. De korreltjes aan de trosgierst zijn niet helemaal droog en daardoor ook goed te verteren. Het is hele goede voeding voor jonge parkietje die leren eten van zaadjes, voor zwakkere parieten die niet willen eten maar wel trosgierst willen, broedende paartjes, etc Geef ze alleen niet te veel trosgierst want daar worden ze dik van."
                  action={{
                    type: "none",
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={3.9} mb={"3em"}>
                <CenteredBlogCard
                  image={foto_v4}
                  title="Ei-/Krachvoer"
                  description="Ei-/Krachtvoer is een belangrijke aanvulling, het is makkelijk te verteren en er zitten veel goede voedingsstoffen in. Het wordt in de broedtijd veel gegeven omdat daar de jongen mee worden gevoed en in de ruitijd als de veren worden vernieuwd."
                  action={{
                    type: "internal",
                    route: "/recepten",
                    color: "info",
                    label: "Recept"
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={0.15}></Grid>
              <Grid item xs={12} lg={3.9} mb={"3em"}>
                <CenteredBlogCard
                  image={foto_v5}
                  title="Vruchten krachtvoer"
                  description="Dit is speciaal voer als extratje voor de parkiet."
                  action={{
                    type: "none",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={0.15}></Grid>
              <Grid item xs={12} lg={3.9} mb={"3em"}>
                <CenteredBlogCard
                  image={foto_v6}
                  title="Kiemzaad"
                  description="Kiemzaad"
                  action={{
                    type: "internal",
                    route: "/recepten",
                    color: "info",
                    label: "2 recepten"
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={3.9} mb={"3em"}>
                <CenteredBlogCard
                  image={foto_v7}
                  title="Schelpenzand"
                  description="gemalen schelpen heet schelpengrit, dat is inderdaad grotendeels kalk. schelpenzand voor de vogelkooi is zilverzand waaraan schelpengrit is toegevoegd. Op die manier kunnen de vogels extra kalk opnemen."
                  action={{
                    type: "none",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={0.15}></Grid>
              <Grid item xs={12} lg={3.9} mb={"3em"}>
                <CenteredBlogCard
                  image={foto_v8}
                  title="Hout/Beuken snippers"
                  description="Houtsnippers zijn gedroogd beukenhout dat een laag vochtgehalte heeft waardoor schimmel wordt verkomen, je hebt verschillende dikte's in 5 of 20 kilo zak. Ik zelf gebruik het niet in de kooi maar op de speeltuin van de parkieten, het voordeel vind ik dat je lang met een zak doet omdat het de bodem sneller vult dan bv schelpenzand daar heb je meer van nodig. Houtsnippers worden ook veel gebruikt in een kamervoliere."
                  action={{
                    type: "none",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={0.15}></Grid>
              <Grid item xs={12} lg={3.9} mb={"3em"}>
                <CenteredBlogCard
                  image={foto_v9}
                  title="Grit"
                  description="Grit is belangrijk voor de spijsvertering van de parkiet, ze hebben geen gebit om hun eten fijn te knauwen zoals wij dat doen. In schelpenzand zit grit al verwerkt maar als je dat niet als bodembedekking gebruikt, zoals Hout/Beuken snippers, moet je grit er apart bij geven. Grit moet iedere dag beschikbaar zijn, ze eten er net zoveel van als dat ze nodig hebben. Ook in sepia zit kalk, deze kun je ophangen in de kooi op een plek waar ze er goed bij kunnen."
                  action={{
                    type: "none",
                  }}
                />
              </Grid>
              
              <Grid item xs={12} lg={3.9} mb={"3em"}></Grid>
              <Grid item xs={12} lg={0.15}></Grid>
              <Grid item xs={12} lg={3.9} mb={"3em"}>
                <CenteredBlogCard
                  image={foto_v10}
                  title="Groenten en fruit"
                  description="Om gezond te blijven heeft de parkiet net als wij mensen ook groenten en fruit nodig, Ze mogen best elke dag een stukje. Niet elke parkiet zal er gelijk van gaan eten en moet er even aan wennen, bied het gewoon iedere keer aan. waarschijnlijk gaat de parkiet er toch eens van proeven. Wat je bijvoorbeeld kan geven is een stukje appel, peer, banaan, wortel, andijvie, sperzieboon, etc. Geef niet teveel sla want sommige parkieten krijgen er diarree van en kunnen daarme infecties oplopen. Houd deze diarree meet dan twee dagen aan wordt aangeraden de dierenarts raad te plegen. LET OP! Geef ze nooit avocado dat is zeer giftig voor de parkiet."
                  action={{
                    type: "none",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={0.15}></Grid>
              <Grid item xs={12} lg={3.9} mb={"3em"}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Voeding;
