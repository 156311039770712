/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import * as React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Foto1 from "assets/images/Zelf/Zelf-52.jpg"
import Foto2 from "assets/images/vogels/Vogels-130.jpg"
import Foto3 from "assets/images/vogels/Vogels-6.jpg"
import Foto4 from "assets/images/vogels/Vogels-128.jpg"
import Foto5 from "assets/images/Zelf/Zelf-27.jpg"
import Foto6 from "assets/images/vogels/Vogels-178.jpg"
import Foto7 from "assets/images/vogels/Vogels-179.jpg"

// Accordion
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function EngelseGrasParkiet() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  
  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
              De Engelse Grasparkiet
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <img src={Foto1} alt="" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
              Geschiedenis
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            De grasparkiet (Melopsittacus undulatus) behoort tot de papegaaiachtigen en komt in het wild in grote zwermen in Australië voor. Ruim 150 jaar geleden werd de grasparkiet in Europa ingevoerd. Ze worden gehouden in een kooi of volière. Door ontsnappingen leven ook hier steeds meer parkieten in het wild. In sommige stadsparken zijn hele groepen waar te nemen.
            </p>
            <MKTypography variant="h2" mb={"1em"}>
              Algemeen
            </MKTypography>
            
            <p style={{marginBottom:"1em"}}>
            Als parkieten alleen in een kooi worden gehouden hebben ze veel aandacht nodig. Het zijn echte groepsdieren en daarom is het beter om er twee of meer te houden (bij voorkeur een even aantal). Speelgoed en klimgerei zijn noodzakelijk bij het leven in een kooi. Parkieten zijn vrolijke ondernemende vogels die erg tam kunnen worden, vooral als ze van jongs af aan met mensen in aanraking zijn geweest. Sommige grasparkieten kunnen gaan praten, de meeste doen dat echter niet.
            </p>
          </Grid>

          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
              Neusdop
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            De neusdop is het stukje kaal vlees op de bovensnavel waarin de neusgaten zitten. Hieraan is te zien of de parkiet een mannetje (man) of een vrouwtje (pop) is. Mannetjes hebben een blauwe neusdop terwijl deze bij een pop roze tot lichtbeige is. Bij de pop wordt de neusdop bruin als ze in broedstemming is. Jonge mannetjes hebben meestal een lichtbeige tot bruine neusdop en jonge popjes een blauwe met witte ringen om de neusgaten, waardoor het voor leken moeilijk is om het geslacht te bepalen. Na ongeveer 6 weken, het moment dat de jongen de ouders kunnen verlaten, is het voor een ervaren kweker het verschil wel te zien. De neusdop verkleurt na de jeugdrui (ongeveer 12 weken).
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto2} alt="" width="100%" />
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
              Ogen
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Vogels hebben in verhouding tot het hele lichaam grote ogen. De ogen van de parkiet zijn sterk naar opzij geplaatst. Parkieten zijn dus vrijwel onmogelijk ongemerkt te besluipen. Het gezichtsveld dat door beide ogen tegelijk wordt gezien, en waar ze dus diepte kunnen waarnemen, is maar klein. Om goed te kunnen zien moet de parkiet dus zijn kop bewegen. Parkieten kunnen razendsnel accommoderen (omschakelen van dichtbij naar ver weg zien). Een parkiet heeft een zeer snel reactievermogen. Dit heeft hij nodig om voor zijn vijanden te kunnen vluchten.
            </p>
          </Grid>

          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
              Snavels & Nagels
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Zoals alle papegaaiachtigen heeft de grasparkiet een sterk gekromde bovensnavel. De ondersnavel is in rust in het verenkleed verborgen. De snavel wordt gebruikt om te eten, maar ook om te klimmen. Parkieten kunnen een gevoelige beet uitdelen in zachte delen van de menselijk lichaam zoals de oorlel of de lip. Bij sommige parkieten wordt de snavel te lang: die moet dan bij de dierenarts geknipt worden. Dit komt vaak voor bij vogels die een leverafwijking hebben door een onvolwaardige voeding. Soms worden ook de nagels te lang. Bij knippen dient men er rekening mee te houden dat het 'leven' (de nagelwortel) ver doorloopt in de nagel. In verband met de snavel en de nagels van de parkiet dienen er niet te dunne houten zitstokken in de kooi te zijn, bij voorkeur uit wilgenhout. Ook mineraalstokken kunnen worden gebruikt. Zandhoesjes die in de dierenwinkel worden aangeboden zorgen vaak voor irritatie van de voetzooltjes.
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto3} alt="" width="100%" />
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
              Poten
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Papegaai-achtigen, waaronder de parkiet, hebben 2 tenen naar voren en 2 tenen naar achteren, in tegenstelling tot zangvogels die 3 tenen naar voren en 1 teen naar achteren hebben. Hierdoor kunnen ze goed klimmen en zich (op muren en dergelijke) vasthouden. Als parkieten jeuk aan hun kop hebben, krabben ze zich, anders dan zangvogels, met hun poot over hun vleugel heen.
            </p>
          </Grid>

          <Grid item xs={12} lg={4}>
            <img src={Foto4} alt="" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
              Lichaamstemperatuur
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Vogels zijn warmbloedige dieren. Ze hebben een normale lichaamstemperatuur van 40 tot 42 graden. Als de parkiet het koud heeft of ziek is zet hij zijn veren op ('bol zitten'). De laag warme lucht rond het lichaam wordt dan dikker. Om warmte kwijt te raken gaat hij sneller ademhalen (80 tot 100 keer per minuut is normaal) of met z'n vleugels uitgespreid zitten. Vogels kunnen niet zweten. Parkieten kunnen slecht tegen tocht. Als er te grote temperatuurverschillen zijn in z'n omgeving dan gaat hij veel vaker dan tweemaal per jaar in de rui.
            </p>
            <MKTypography variant="h2" mb={"1em"}>
            Praten
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Parkieten kunnen, net als de meeste andere papegaaiachtigen, leren geluiden te imiteren. Het is moeilijk om aan te leren maar het resultaat kan goed zijn. Met trainen kan men het beste vroeg beginnen. Het maakt niet uit of de parkiet een mannetje of een vrouwtje is, ze kunnen beiden even goed leren klanken na te bootsen. Of het leren praten succesvol is hangt van allerlei omstandigheden af. Het aanleren begint met eenvoudige woordjes zoals 'hallo' of met de naam van de vogel, als die tenminste niet langer is dan twee lettergrepen. De stem van een vrouw of kind zal gemakkelijker geïmiteerd worden vanwege de hogere toon. Spraakoefeningen dienen iedere dag herhaald te worden, bij voorkeur 's ochtends of in het begin van de avond, wanneer de vogel het actiefst is. De vogel wordt in een rustige omgeving geoefend, zonder afgeleid te worden door televisie, radio, of door andere mensen en vogels. Resultaat wordt bereikt door het steeds blijven herhalen van eenzelfde woord. Nadat een woord met succes is ingeslepen kan aan een volgend woord worden begonnen.
            </p>
          </Grid>

          <Grid item xs={12} lg={4}>
            <img src={Foto5} alt="" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            De kooi
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            De kooi dient in een zonnige of lichte kamer te staan, maar niet in de directe zon en niet op de tocht. De parkiet is een sociaal dier en stelt gezelschap op prijs. Hij kan niet goed tegen rook en kookdampen. Het is goed als hij geregeld kan vliegen, waarbij de veiligheid gelet dient te worden: ramen, zeker in het begin, bedekken, andere huisdieren de kamer uit en giftige kamerplanten verwijderen. Parkieten houden van schone en droge kooien. De kooi moet voldoende ruim zijn, zeker als de parkiet zelden los kan vliegen.
            </p>
            <p style={{marginBottom:"1em"}}>
            Het beste is een rechthoekige kooi met horizontale tralies, aangezien parkieten ook graag over de tralies rondklimmen, en bij voorkeur een vlakke bovenkant. De tralies mogen niet verder dan 12 mm uit elkaar staan, anders kan het kopje er doorheen en misschien niet meer terug. In de kooi horen houten zitstokken. Als deze stokken verschillende diktes hebben, krijgt de parkiet minder gauw problemen met eeltknobbels op zijn voeten. Eventueel kan een zittouw worden aangebracht. Parkieten waarderen houten trapjes om te klauteren en een schommeltje om op te zitten. Een plastic vogeltje of een spiegeltje zijn niet echt nodig, maar er wordt wel druk mee gespeeld als ze aanwezig zijn. Het is echter geen voldoende vervanging voor het gezelschap van een soortgenoot.
            </p>
          </Grid>
          <Grid item xs={12}>
            <p style={{marginBottom:"1em"}}>
            Kooivogels dienen 's middags en 's nachts voldoende rust te krijgen en voldoende tijd om te kunnen slapen. Als de kooi in het midden van een huiskamer staat kan dat in dit opzicht ongunstig zijn. Voor de rust en het gevoel van veiligheid van het dier is het beter als de kooi tegen een muur staat. Bij rustig weer kan de kooi een tijdje buiten geplaatst worden, uit de wind en niet in de volle zon.
            </p>
            <p style={{marginBottom:"1em"}}>
            Als bodembedekking kunnen houtsnippers gebruiken van beukenhout of corbo van maïs. Het ouderwetse schelpenzand is volgens sommigen minder geschikt maar het wordt veel gebruikt. Het is noodzakelijk de bodem van de kooi elke 1-2 weken te verschonen.
            </p>
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
            Levensverwachting
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Grasparkieten kunnen met de juiste voeding en verzorging ongeveer 16 jaar oud worden. De geminddelde leeftijd van parkieten is 8 jaar. De meeste vogels halen deze leeftijd echter niet, dit komt onderandere door wegvliegen, ongelukken bij het rondvliegen, verkeerde voeding of verzorging en een slechte kwaliteit vogel(te lang door gefokt met de ouders). Soms kan de dierenarts een zieke vogel met succes behandelen. Een goed geïnformeerde eigenaar heeft meer kans op een langlevende parkiet.
            </p>
          </Grid>
          
          <Grid item sx={1} lg={2}></Grid>
          <Grid item sx={10} lg={8}>
            <img src={Foto7} alt="" width="100%" />
          </Grid>
          <Grid item sx={1} lg={2}></Grid>
        </Grid>

        <Grid item xs={12}>
          <MKTypography variant="h2" mb={"1em"}>
          Ziektes
          </MKTypography>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <MKTypography>Ingewandswormen</MKTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MKTypography>
                Grasparkieten zijn vrij sterke vogels maar kunnen wel allerlei ziektes krijgen. Bij worminfecties is dit niet altijd vast te stellen door onderzoek van de ontlasting op eieren, omdat de verblijftijd van het voedsel in de darm zo kort is dat er meestal niet veel eieren te vinden zijn. Een vogel die niet lekker is, veel 'bol zit' en weinig actief is kan een aanzienlijke hoeveelheid wormen met zich meedragen. Een kuur met levamisol kan dan soms een flink aantal, bijvoorbeeld 6 à 15, wormen laten uitpoepen. Medicijnen kunnen beter niet gegeven worden zonder advies van een (vogel)dierenarts. In dierenwinkels is wel advies te krijgen en zijn veel middelen, waaronder een keur van antibiotica, te koop, maar er is niet altijd sprake van deskundige en onafhankelijke adviezen. De beste waarborg voor een gezonde vogel en het voorkomen van problemen is in de eerste plaats goede dagelijkse voeding. Verder bevordert ruimte en gelegenheid om te vliegen en een schone kooi met schoon water om te drinken en te baden de gezondheid sterk.
              </MKTypography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <MKTypography>Doorgegroeide nagels</MKTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MKTypography>
                De klauwnagels van parkieten groeien snel,vooral als ze geen geschikte zit- en klimstokken of stammen hebben. Met een scherp nagelschaartje kunnen de nagels worden ingekort.
              </MKTypography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <MKTypography>Doorgeschoten snavel</MKTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MKTypography>
                Een misvormde snavel kan moeilijkheden veroorzaken bij het eten, maar kan op vrijwel dezelfde manier worden verholpen als te lange nagels.
              </MKTypography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
              <MKTypography>Schurftmijt (cnemidocoptes pilae)</MKTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MKTypography>
                Deze aandoening wordt veroorzaakt door een kleine parasitaire mijt en uit zich in kruimelige, schubachtige woekeringen (vooral) rond de snavel, de washuid, poten en voeten. Er zijn speciale middeltjes voor in de handel, waarmee de plekken kunnen worden ingesmeerd. Het is ook mogelijk de snavel en de huid eromheen drie dagen lang tweemaal per dag met een wattenstokje met vaseline in te smeren en deze behandeling na een week te herhalen.
              </MKTypography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
              <MKTypography>Kou en verkoudheden</MKTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MKTypography>
              Deze worden gewoonlijk veroorzaakt door vocht en tocht. De ogen vertonen afscheidingen en de vogel ziet er ziek uit, zit ineengedoken met opgezette veren. De zieke vogel kan in een warm vertrek worden gebracht en krijgt wat vitamine-tonic in het drinkwater.
              </MKTypography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
            <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
              <MKTypography>Verstopping</MKTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MKTypography>
              Droge, harde uitwerpselen tonen aan, dat de vogel een verstopping heeft. Verhoging van de hoeveelheid groenvoer in het dieet en een druppel levertraan door het zaad mengen kan dit verbeteren.
              </MKTypography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
            <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
              <MKTypography>Veerplukken</MKTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MKTypography>
              Een toestand bij poppen (vrouwtjes), die dan niet alleen hun eigen veren, maar ook die van de vogels in hun buurt plukken. Dit wordt beschouwd als een erfelijke aandoening die blijkbaar niet kan worden afgewend. De enige oplossing is het dier een afzonderlijke behuizing te geven.
              </MKTypography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
            <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
              <MKTypography>Legnood</MKTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MKTypography>
              Als een pop problemen heeft met het leggen van een ei, kunnen we haar helpen door wat slaolie onder de staart te wrijven en eventueel de legopening in een wisselbadje (koud/warm) te dompelen, totdat het ei tevoorschijn komt.
              </MKTypography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
            <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
              <MKTypography>Reumatiek</MKTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MKTypography>
                Door tochtige vogelverblijven, natte bodems, te dunne roest- en zitstokken en door kouvatten kan gemakkelijk reumatiek optreden. Aan de gedragingen van de vogel kan men zien dat er veel pijn wordt geleden. De vogel eet niet, heeft verdikkingen aan de gewrichten en kijkt lusteloos uit de ogen. De zit- en slaapstokken kunnen in zo'n geval worden verbeterd (doorsnede zitstok ongeveer 4 tot ca 8 cm; variatie is wenselijk), evenals de algehele conditie van de volière en meubilair. De gewrichten kunnen ingesmeerd worden met kamferspiritus. De vogel wordt in een warme omgeving geplaatst en aan het drinkwater kunnen enkele druppels Geza-Sept toegevoegd worden. De gezwellen kunnen soms gaan etteren; verschillende zalfjes, pure Geza-Sept en dergelijke worden in zulke gevallen wel toegepast. Als een gezwel rijp is dan kan men het doorprikken en met het genoemde middel aanstippen. Men geeft zo'n ziek dier vaak in melk geweekt (wit-)brood, groen- en krachtvoer. Nabehandeling van de gewrichten met slaolie kan soms tot een bevredigend eindresultaat leiden.
              </MKTypography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
            <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
              <MKTypography>Kruipersziekte of polyoma</MKTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MKTypography>
                Er is een extreme- en een milde vorm van polyoma. Bij de extreme vorm van polyoma ziet men tot 10 à 15 dagen een normale ontwikkeling, dan plotselinge sterfte zonder verder symptomen. Andere nestjongen van hetzelfde ouderpaar tonen een opgezwollen buik en uitdrogingsverschijnselen wat vooral goed zichtbaar is aan loopbenen en tenen, die enigszins verschrompeld aandoen, soms ziet men zenuwafwijkingen. De dons- en contourveren veren van zulke nestjongen zijn sterk onderontwikkeld en er is veel sterfte in de eerste drie levensweken, soms oplopend tot 100%. Jongen die overleven tonen bevederingsstoornissen in de dekbevedering terwijl de grote vleugel- en staartpennen nauwelijks zijn ontwikkeld waardoor de vogels niet kunnen vliegen. Het zijn in alle gevallen onderontwikkelde vogels die niet meer herstellen. Bij de milde vorm van polyoma - deze treedt op als de jonge vogels na de eerste dag met het virus worden geïnfecteerd - laten de jonge grasparkieten vlak voordat ze het nestblok verlaten, alle slag- en staartpennen vallen. Ook de milde vorm van polyoma kent verschillende gradaties variërend van het verlies van enkele vleugelpennen tot de zwaardere gevallen, waarbij ook de lichaamsbevedering is aangetast. In de spoel van de afgeworpen vleugelpennen zien we een roodbruine bloederige massa, reden waarom wel van bloedpennen gesproken wordt. De veerschachten zijn bros en tonen enigszins gekrulde baarden. Aan het einde van de schacht zijn de pennen iets geknikt. Behalve het feit dat de jonge vogels niet of nauwelijks kunnen vliegen en zich over de grond of langs het gaas kruipend voortbewegen, vandaar de benaming kruiper, zijn ze verder vitaal en lijken volkomen gezond. Deze vogels herstellen gewoonlijk na enige tijd helemaal, waarbij de meer ernstige gevallen soms wat in groei achterblijven in vergelijking met hun niet aangetaste soortgenoten.
              </MKTypography>
            </AccordionDetails>
          </Accordion>
          
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default EngelseGrasParkiet;
