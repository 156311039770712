import { Photo } from "react-photo-album";


import Foto1 from "assets/images/Op soort/Jonge vogels/Vogels-23.jpg"
import Foto2 from "assets/images/vogels/Vogels-66.jpg"
import i1 from "assets/images/Zelf/Zelf-12.jpg"

import f1 from "assets/images/vogels/Vogels-5.jpg"
import f2 from "assets/images/vogels/Vogels-28.jpg"
import f3 from "assets/images/vogels/Vogels-33.jpg"
import f4 from "assets/images/vogels/Vogels-62.jpg"
import f5 from "assets/images/vogels/Vogels-67.jpg"
import f6 from "assets/images/vogels/Vogels-6.jpg"
import f7 from "assets/images/vogels/Vogels-20.jpg"
import f8 from "assets/images/vogels/Vogels-49.jpg"
import f9 from "assets/images/vogels/Vogels-51.jpg"
import f10 from "assets/images/vogels/Vogels-57.jpg"
import f11 from "assets/images/vogels/Vogels-66.jpg"
import f12 from "assets/images/vogels/Vogels-76.jpg"
import f13 from "assets/images/vogels/Vogels-134.jpg"
import f14 from "assets/images/vogels/Vogels-153.jpg"
import f15 from "assets/images/vogels/Vogels-155.jpg"
import f16 from "assets/images/vogels/Vogels-193.jpg"
import f17 from "assets/images/vogels/Vogels-194.jpg"
import f18 from "assets/images/vogels/Vogels-205.jpg"
import f19 from "assets/images/vogels/Vogels-211.jpg"
import f20 from "assets/images/vogels/Vogels-217.jpg"
import f21 from "assets/images/vogels/Vogels-218.jpg"
import f22 from "assets/images/vogels/Vogels-220.jpg"
import f23 from "assets/images/vogels/Vogels-221.jpg"
import f24 from "assets/images/vogels/Vogels-222.jpg"
import f25 from "assets/images/Zelf/Zelf-10.jpg"
import f26 from "assets/images/Zelf/Zelf-22.jpg"
import f27 from "assets/images/Zelf/Zelf-30.jpg"
import f28 from "assets/images/Zelf/Zelf-4.jpg"
import f29 from "assets/images/Zelf/Zelf-6.jpg"
import f30 from "assets/images/Zelf/Zelf-7.jpg"
import f31 from "assets/images/Zelf/Zelf-62.jpg"

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

function assetLink(asset, width) {
  return `https://assets.react-photo-album.com/_next/image?url=${encodeURIComponent(`/_next/static/media/${asset}`)}&w=${width}&q=75`;
}

const TamMakenPhotos = [
  {
    asset: f1,
    width: 798,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f2,
    width: 798,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f3,
    width: 798,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f4,
    width: 798,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f5,
    width: 798,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f6,
    width: 800,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f7,
    width: 451,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f8,
    width: 800,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f9,
    width: 450,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f10,
    width: 798,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f11,
    width: 451,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f12,
    width: 798,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f13,
    width: 450,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f14,
    width: 451,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f15,
    width: 798,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f16,
    width: 798,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f17,
    width: 798,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f18,
    width: 798,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f19,
    width: 451,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f20,
    width: 451,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f21,
    width: 451,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f22,
    width: 798,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f23,
    width: 798,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f24,
    width: 798,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f25,
    width: 800,
    height: 532,
    alt: "Hiking boots",
  },
  {
    asset: f26,
    width: 800,
    height: 532,
    alt: "Hiking boots",
  },
  {
    asset: f27,
    width: 399,
    height: 600,
    alt: "Hiking boots",
  },
  {
    asset: f28,
    width: 800,
    height: 532,
    alt: "Hiking boots",
  },
  {
    asset: f29,
    width: 800,
    height: 532,
    alt: "Hiking boots",
  },
  {
    asset: f30,
    width: 800,
    height: 532,
    alt: "Hiking boots",
  },
  {
    asset: f31,
    width: 798,
    height: 600,
    alt: "Hiking boots",
  },
].map(
  ({ asset, alt, width, height }) =>
    ({
      src: asset,
      alt,
      width,
      height,
      srcSet: breakpoints.map((breakpoint) => ({
        src: asset,
        width: breakpoint,
        height: Math.round((height / width) * breakpoint),
      })),
    }),
);

export default TamMakenPhotos;
