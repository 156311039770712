import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Fade } from "@material-ui/core";


const FadeCarousel = ({ dataArray, fadeTime, showTime }) => {
  const [checked, setChecked] = React.useState(true);
  const [carouselData, setCarousel] = React.useState(dataArray[0]);


  useEffect(() => {
    var count = 0;
    // Interval Fade
    setInterval(() => {
        setChecked(true);
        setTimeout(() => {
            setChecked(false);

            setTimeout(() => {
                if (count == dataArray.length - 1) {
                    count = 0;
                } else count++;
                setCarousel(dataArray[count])
            }, fadeTime*2)
        }, fadeTime + showTime)
    }, fadeTime*3 + showTime);
  }, []);

  return (
      <Fade
        in={checked}
        timeout={{
          enter: fadeTime * 2,
          exit: fadeTime * 2
        }}
        appear={"true"}
      >
        <img src={carouselData} width="100%" alt="" />
      </Fade>
  );
};

export default FadeCarousel;