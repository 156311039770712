/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Foto from "assets/images/Zelf/Zelf-74.jpg"

import Logo from "assets/images/Logo.png"

import fma1 from "assets/images/HomePage/Maandag/1.jpg"
import fma2 from "assets/images/HomePage/Maandag/2.jpg"
import fma3 from "assets/images/HomePage/Maandag/3.jpg"
import fma4 from "assets/images/HomePage/Maandag/4.jpg"
import fma5 from "assets/images/HomePage/Maandag/5.jpg"

import fdi1 from "assets/images/HomePage/Dinsdag/1.jpg"
import fdi2 from "assets/images/HomePage/Dinsdag/2.jpg"
import fdi3 from "assets/images/HomePage/Dinsdag/3.jpg"
import fdi4 from "assets/images/HomePage/Dinsdag/4.jpg"
import fdi5 from "assets/images/HomePage/Dinsdag/5.jpg"

import fwo1 from "assets/images/HomePage/Woensdag/1.jpg"
import fwo2 from "assets/images/HomePage/Woensdag/2.jpg"
import fwo3 from "assets/images/HomePage/Woensdag/3.jpg"
import fwo4 from "assets/images/HomePage/Woensdag/4.jpg"
import fwo5 from "assets/images/HomePage/Woensdag/5.jpg"

import fdo1 from "assets/images/HomePage/Donderdag/1.jpg"
import fdo2 from "assets/images/HomePage/Donderdag/2.jpg"
import fdo3 from "assets/images/HomePage/Donderdag/3.jpg"
import fdo4 from "assets/images/HomePage/Donderdag/4.jpg"
import fdo5 from "assets/images/HomePage/Donderdag/5.jpg"

import fvr1 from "assets/images/HomePage/Vrijdag/1.jpg"
import fvr2 from "assets/images/HomePage/Vrijdag/2.jpg"
import fvr3 from "assets/images/HomePage/Vrijdag/3.jpg"
import fvr4 from "assets/images/HomePage/Vrijdag/4.jpg"
import fvr5 from "assets/images/HomePage/Vrijdag/5.jpg"

import fza1 from "assets/images/HomePage/Zaterdag/1.jpg"
import fza2 from "assets/images/HomePage/Zaterdag/2.jpg"
import fza3 from "assets/images/HomePage/Zaterdag/3.jpg"
import fza4 from "assets/images/HomePage/Zaterdag/4.jpg"
import fza5 from "assets/images/HomePage/Zaterdag/5.jpg"

import fzo1 from "assets/images/HomePage/Zondag/1.jpg"
import fzo2 from "assets/images/HomePage/Zondag/2.jpg"
import fzo3 from "assets/images/HomePage/Zondag/3.jpg"
import fzo4 from "assets/images/HomePage/Zondag/4.jpg"
import fzo5 from "assets/images/HomePage/Zondag/5.jpg"

import FadeCarousel from "components/FadeCarousel";



function IndexPage() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [images, setImages] = useState()
  const [dZo, setDZo] = useState("none")
  const [dMa, setDMa] = useState("none")
  const [dDi, setDDi] = useState("none")
  const [dWo, setDWo] = useState("none")
  const [dDo, setDDo] = useState("none")
  const [dVr, setDVr] = useState("none")
  const [dZa, setDZa] = useState("none")
  

  useEffect(() => {
    const d = new Date();
    let day = d.getDay();
    console.log(day)
    switch (day)
    {
      case 0:
        setDZo("block")
        break;
      case 1:
        setDMa("block")
        break;
      case 2:
        setDDi("block")
        break;
      case 3:
        setDWo("block")
        break;
      case 4:
        setDDo("block")
        break;
      case 5:
        setDVr("block")
        break;
      case 6:
        setDZa("block")
        break;
      default:
        break;
    }
  }, [])

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <img alt="" src={fullTitle} width="100%" style={{marginTop:"4em"}}/> 
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} display={dZo}>
            <FadeCarousel dataArray={[fzo1,fzo2,fzo3,fzo4,fzo5]} fadeTime={1000} showTime={2500} />
          </Grid>
          <Grid item xs={12} lg={6} display={dMa}>
            <FadeCarousel dataArray={[fma1,fma2,fma3,fma4,fma5]} fadeTime={1000} showTime={2500} />
          </Grid>
          <Grid item xs={12} lg={6} display={dDi}>
            <FadeCarousel dataArray={[fdi1,fdi2,fdi3,fdi4,fdi5]} fadeTime={1000} showTime={2500} />
          </Grid>
          <Grid item xs={12} lg={6} display={dWo}>
            <FadeCarousel dataArray={[fwo1,fwo2,fwo3,fwo4,fwo5]} fadeTime={1000} showTime={2500} />
          </Grid>
          <Grid item xs={12} lg={6} display={dDo}>
            <FadeCarousel dataArray={[fdo1,fdo2,fdo3,fdo4,fdo5]} fadeTime={1000} showTime={2500} />
          </Grid>
          <Grid item xs={12} lg={6} display={dVr}>
            <FadeCarousel dataArray={[fvr1,fvr2,fvr3,fvr4,fvr5]} fadeTime={1000} showTime={2500} />
          </Grid>
          <Grid item xs={12} lg={6} display={dZa}>
            <FadeCarousel dataArray={[fza1,fza2,fza3,fza4,fza5]} fadeTime={1000} showTime={2500} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <p style={{marginBottom:"1em"}}>
            Geachte bezoeker,
            </p>
            
            <p style={{marginBottom:"1em"}}>
            Al vast bedankt voor het bezoeken van mijn website, ik zal me even voor stellen.
            Ik ben <a href="/Contact">Peter</a> en heb als hobby het kweken en tam maken van parkieten.
            U kunt dus bij mij parkieten kopen die hand tam zijn of voor in de voli&#235;re.
            </p>
            
            <p style={{marginBottom:"1em"}}>
            Ook voor een bepaalde kleur of andere keuze`s kunt u altijd bij 1 van ons twee terecht, het voordeel is dat als de 1 op het moment niets heeft zitten, dan waarschijnlijk de andere wel omdat wij nauw contact hebben met elkaar, en precies weten wat wij voor jonge parkietjes hebben zitten.
            Natuurlijk is alles afhankelijk van de broedperiode`s en de parkietjes gaan bij ons niet weg voordat ze zelf kunnen eten en hand tam zijn.
            </p>
          </Grid>
          <Grid item xs={12}>
            <p style={{marginBottom:"1em"}}>
            De jonge parkietjes worden van af 3 a 4 weken na dat ze uit het ei zijn gekomen al vast gepakt en uit het blok gehaald.
            Dit doen we om de dag ongeveer 2 tot 3 min, zo groeien ze echt op met de hand.
            zodra ze uit vliegen worden ze verder, zorgvuldig tam gemaakt zo dat U daar zelf al een stuk minder werk aan zult hebben en wel gelijk het plezier er van kan hebben.
            </p>
          </Grid>

          <Grid item xs={12} lg={8}>
          <p style={{marginBottom:"1em"}}>
            De jonge parkietjes in de dierenwinkel worden elke keer opgejaagd door een hand die weer in de kooi komt als er een parkietje verkocht wordt en vaak gebeurt dit meerdere malen per dag.
            <br/>
            Maar onze parkieten zijn niet bang voor een hand en krijsen niet als u in de buurt komt.<br/>
            Ze blijven rustig zitten als u ze uit hun kooi haalt en vliegen niet gelijk weg uit angst en bijten niet als u ze vast pakt.<br/>
            </p>
            <p style={{marginBottom:"1em"}}>
            Om dit voor elkaar te krijgen doe we dit al van uit het nestblok en als hun donsveren kleur krijgen zet ik ze ook bij andere mensen op hun handen en dat steeds langer.
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto} alt="" width="100%" />
          </Grid>

          <Grid item xs={12}>
            <p style={{marginBottom:"1em"}}>
            Ze leven buiten in een voli&#235;re dus zullen ze niet zo snel last hebben van de kou als ze vervoerd worden. 
            U hoeft niets mee te nemen als U een parkiet komt halen, want dan krijgt U er een vervoersdoosje en wat handige tips bij.
            </p>
            <p style={{marginBottom:"1em"}}>
            Als u intresse heeft of op zoek bent naar een bepaalde kleur kunt u een van ons mailen of bellen voor verdere informatie 
            of een afspraak
            </p>
            <p style={{marginBottom:"1em"}}>
            Neem gerust <a href="Contact.php">contact</a> met ons op en helpen u met raad en daad.
            </p>
          </Grid>

        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default IndexPage;
