/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Foto1 from "assets/images/vogels/Vogels-119.jpg"
import Foto2 from "assets/images/Op soort/Catharina/Vogels-2.jpg"
import Foto3 from "assets/images/Op soort/Catharina/Vogels-3.jpg"
import Foto4 from "assets/images/vogels/Vogels-144.jpg"

function Kakariki() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
              Kakariki
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Geschiedenis
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Kakariki's vinden hun oorsprong in Nieuw-Zeeland en omringende eilanden, waar er in het verleden nog 10 soorten in het wild voorkwamen. Dit is gereduceerd tot 7 soorten, de andere 3 soorten zijn al uitgestorven, de meeste van de nog bestaande soorten worden met hun voortbestaan in het wild bedreigd.
            </p>
            <MKTypography variant="h2" mb={"1em"}>
            Tam maken
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Kakariki's zijn vrij makkelijk "tam" te maken mits je wel begint met "tam" maken als ze jong zijn, en zullen ze dus ook altijd naar je toekomen en bij je komen zitten.
            Fluiten en “schreeuwen” doet tot in tegenstelling van de meeste parkieten soorten een kakariki niet, wel maken ze een vreemd geluid dat enigszins lijkt op het mekkeren van een geit. Kakariki's zijn schitterende vogels met een super nieuwsgierig, lief en eigenwijs karakter, wat voor mij dan ook goede redenen zijn om ze te houden.
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto1} alt="" class="imgright" width="100%" />
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="h2" mb={"1em"}>
              Verspreiding
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Kakariki's komen endemisch enkel voor op Nieuw-Zeeland. In het wild zijn ze kwetsbaar door het verlies van habitat (bos en hoog struikgewas) en door de vernietiging van nesten door ingevoerde zoogdieren (ratten bijvoorbeeld). Op de grootste eilanden van Nieuw-Zeeland (het noorder- en zuidereiland) komt de kakariki maar weinig meer voor, maar op de kleinere eilanden (waar dus minder menselijke activiteit is en ook minder uitheemse zoogdiersoorten), heeft de soort redelijk goed stand kunnen houden. De vogel kweekt makkelijk in gevangenschap en ondertussen zijn al een aantal weer uitgezet op roofdiervrije eilanden (bijvoorbeeld op Kapiti, een eiland op 8 kilometer van het noordereiland en van waaruit waarschijnlijk enkele exemplaren overgevlogen zijn).
            </p>
          </Grid>

          <Grid item xs={12} lg={4}>
            <img src={Foto2} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Kenmerken
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Kakariki's kunnen zeker 16 jaar oud worden in gevangenschap. Ze vormen paartjes die hun leven lang bij elkaar blijven. Kakariki's beginnen al met broeden als ze 3/4 maanden oud zijn. Tot hun 12de jaar blijven ze broeden.
            </p>
            <MKTypography variant="h2" mb={"1em"}>
            Broedgelegenheid
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Deze vogels zijn niet bepaald kieskeurig wat betreft hun nestkast, maar de mannetjes kunnen wel hun nest verdedigen tegen indringers (andere soorten). De meest gangbare maat ligt rond 30 x 15 x 30 cm met een invlieggat van 6 cm. Op de bodem brengt men wat grofzaagsel en rothout aan. Voor de rest zijn deze vogels zeer verdraagzaam ten opzichte van andere parkieten, zodat deze goed samen zijn te houden in een volière.
            </p>
          </Grid>

          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Broeden
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            Kakariki's broeden het hele jaar door en produceren vier legsels als ze de kans krijgen. Twee legsels in gevangenschap is echter beter om het vrouwtje niet teveel uit te putten. De legsels bestaan meestal uit 5 tot 9 eieren (soms zelfs 12). De broedtijd bedraagt 18 tot 21 dagen (soms 25). Alleen het vrouwtje broedt. Een vrouwtje begint soms al met een tweede leg in een tweede nestkast als de kuikens van de eerste leg nog maar 3 weken oud zijn en nog niet zijn uitgevlogen. Na een week hebben de kuikens wit dons op de kop en na 10 dagen openen ze de ogen. Na vier weken zijn de kuikens compleet bevederd en na vijf weken vliegen ze uit. Na acht weken zijn ze volledig zelfstandig.
            </p>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src={Foto3} alt="" class="imgright" width="100%" />
          </Grid>

          <Grid item xs={12} lg={4}>
            <img src={Foto4} alt="" class="imgright" width="100%" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h2" mb={"1em"}>
            Voeding
            </MKTypography>
            <p style={{marginBottom:"1em"}}>
            De voeding levert door de nieuwsgierige aard van de vogel geen probleem op; elke nieuwigheid wordt vrijwel onmiddellijk aan een grondig onderzoek onderworpen. Ze krabben met hun poten alles door elkaar en overal heen zoals de kippen. De voeding zelf bestaat uit een zaadmengsel voor grote parkieten, aangevuld met eivoeder, vrij veel fruit en groenvoer, twijgen van bomen, sepiagrit en geregeld vers water, daar ze dikwijls zelfs meermalen per dag baden.
            </p>
          </Grid>
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Kakariki;
