/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import * as React from 'react';

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import IconButton from "assets/theme/components/iconButton";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

import axios from "axios";
import { Alert, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, Pagination, TablePagination, TextField } from "@mui/material";
import CustomReviewCard from "examples/Cards/ReviewCards/CustomReviewCard";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import fullLogo from "assets/images/Menubalk.gif"
import fullTitle from "assets/images/Titel.gif"
import logo from "assets/images/Logo.png"
import bgImage from "assets/images/Achtergrond.bmp";
import bgImage2 from "assets/images/achtergrond.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";

function Gastenboek() {
  const [rememberMe, setRememberMe] = useState(false)
  const handleSetRememberMe = () => setRememberMe(!rememberMe)
  const [gastenboekData, setGastenboekData] = useState()
  const [gastenboekLoaded, setGastenboekLoaded] = useState()
  const [page, setPage] = useState(1)
  const [itemCount, setItemCount] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [open, setOpen] = useState(false)

  const [bericht, setBericht] = useState("")
  const [berichtMsg, setBerichtMsg] = useState("1250 karakters over")
  const [berichtColor, setBerichtColor] = useState("success")
  const [naam, setNaam] = useState("")
  const [plaats, setPlaats] = useState("")
  const [titel, setTitel] = useState("")
  const [titelError, setTitelError] = useState(false)
  const [berichtError, setBerichtError] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)

  const berichtChanged = (event) => {
    setBericht(event.target.value)
    let remaining = 1250 - event.target.value.length
    setBerichtMsg(remaining + " karakters over.")
    if (remaining > 100)
      setBerichtColor("success")
    else if (remaining > 12)
      setBerichtColor("warning")
    else
      setBerichtColor("error")
  }

  const checkandsave = async (event) => {
    if (titel.length < 1)
      setTitelError(true)
    else
      setTitelError(false)

    if (bericht.length > 1250 || bericht.length < 1)
      setBerichtError(true)
    else
      setBerichtError(false)
  
    if (!titelError && !berichtError)
    {
      await axios.post('api/api.php/nieuw_bericht', {
        naam: naam,
        plaats: plaats,
        titel: titel,
        bericht: bericht,
        pw: "!poNieuw_Bericht"
      })
        .then(res => {
            if (res.data === "SUCCESS")
            {
              resetForm()
              setOpen(false)
              loadMessages()
              setAlertOpen(true)
            }
            else
            {
              console.log("error")
            }
        })
    }
  }

  useEffect(() => {
    axios.defaults.withCredentials = true;
    console.log('load')
    loadMessages()
  }, [])

  const loadMessages = async () => {
    await axios.get('api/api.php/gastenboek')
    .then(res => {
        if (res.status === 202)
        {
            console.log("geen Data")
            setGastenboekData([])
            setItemCount(0)
        }
        else if (res.status === 200)
        {
          setGastenboekData(res.data)
          setItemCount(res.data.length)
          console.log("Found: " + itemCount + " Records" )
        }
        setGastenboekLoaded(true)
    })
  }

  const resetForm = (event) => {
    setNaam("")
    setBericht("")
    setBerichtMsg("1250 karakters over")
    setBerichtColor("success")
    setPlaats("")
    setTitel("")
  }
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <DefaultNavbar
        brand=""
        logo={logo}
        routes={routes}
        sticky
        center
        // transparent
        // light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={"2em"}
            >
              Gastenboek
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 5 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: `url(${bgImage2})`
        }}
      >
        <Grid container>
          <Grid item xs={12} textAlign="center" justifyContent="center" sx={{marginBottom:"1em"}}>
            <Collapse in={alertOpen}>
              <Alert 
                icon={<CheckIcon fontSize="inherit" />} 
                action={
                  <CloseIcon fontSize="inherit" onClick={(event) => {setAlertOpen(false)}} style={{cursor:"pointer"}} />
                } 
                color="success"
                variant="filled">
                  Bericht toegevoegd</Alert>
            </Collapse>
          </Grid>
          <Grid item xs={12} textAlign="center" justifyContent="center" sx={{marginBottom:"1em"}}>
            <MKButton variant="gradient" color="info" size="large" onClick={() => {setOpen(true)}}>Plaats Nieuw Bericht</MKButton>
          </Grid>
          { gastenboekLoaded ?
            <>
              {gastenboekData.map((item, i) => {
                { if (i >= ((page-1)*itemsPerPage) && (i <= (page*itemsPerPage)-1))
                  {
                    return <>
                            <Grid item lg={2} display={{ xs: "none", lg: "inline-block" }}></Grid>
                            <Grid item xs={12} lg={8} style={{marginBottom: "1em", padding: "0px 0.25em"}}>
                              <CustomReviewCard color="secondary" name={(item.Naam) ? item.Naam : "-"} plaats={(item.Plaats) ? item.Plaats : "-"} titel={(item.Titel) ? item.Titel : ""} review={item.Tekst} />
                            </Grid>
                            <Grid item lg={2} display={{ xs: "none", lg: "inline-block" }}></Grid>
                          </>
                  }
                }
              })}
              
              <Grid item xs={3} lg={4}></Grid>
              <Grid item xs={8} lg={6}>
                <Pagination count={Math.floor(itemCount/itemsPerPage)} color="primary" showFirstButton showLastButton onChange={handleChangePage}/>
                {/* <TablePagination
                  component="div"
                  count={itemCount}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={itemsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  /> */}
              </Grid>
              <Grid item xs={2} lg={3}></Grid>
              
              
              </>
          : <CircularProgress />
          }
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <Dialog
        fullWidth
        open={open}
        onClose={(event, reason) => {setOpen(false)}}
      >
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            id="outlined-naam"
            label="Naam"
            value={naam}
            onChange={(event) => { setNaam(event.target.value) }}
          />
          <TextField
            fullWidth
            margin="dense"
            id="outlined-plaats"
            label="Plaats"
            value={plaats}
            onChange={(event) => { setPlaats(event.target.value) }}
          />
          <TextField
            fullWidth
            required
            margin="dense"
            id="outlined-titel"
            label="Titel"
            value={titel}
            onChange={(event) => { setTitel(event.target.value) }}
            error={titelError}
          />
          <TextField
            required
            margin="dense"
            id="outlined-bericht"
            label="Bericht"
            fullWidth
            multiline
            rows={12}
            value={bericht}
            onChange={berichtChanged}
            error={berichtError}
          />
          <MKTypography
              variant="caption"
              color={berichtColor}
            >
              {berichtMsg}
            </MKTypography>
        </DialogContent>
        <DialogActions>
          <MKButton variant="gradient" color="success" onClick={checkandsave}>Plaats Bericht</MKButton>
          <MKButton variant="gradient" color="error" onClick={resetForm}>Reset formulier</MKButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Gastenboek;
